import React from 'react';
import { FormGroup, Label, Spinner } from 'reactstrap';
import type { TypeaheadRef } from 'react-bootstrap-typeahead';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';
import type { IWorkflow } from 'types/IWorkflows';
import type { ITypeAheadReactHookForm } from 'types/ITypeAheadReactHookForm';
import { useGetWorkflows } from 'apiHooks/WorkFlows.Hook';
import { decode } from 'html-entities';

function WorkflowsSelect({
  className,
  id,
  onValueChange,
  onBlur,
  name,
  defaultValue,
  value,
  isInvalid,
} : ITypeAheadReactHookForm) {
  const typeaheadRef = React.useRef<TypeaheadRef>(null);
  const [touched, setTouched] = React.useState(false);

  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetWorkflows();

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return (
    <Typeahead
      id={id}
      ref={typeaheadRef}
      className={className || ''}
      highlightOnlyResult
      options={data}
      labelKey={(workflow) => decode((workflow as IWorkflow).title)}
      placeholder=" "
      onChange={(selected) => {
        if (!onValueChange) {
          return;
        }
        const workflowSelected = selected as IWorkflow[];
        if (workflowSelected.length === 0) {
          onValueChange(undefined);
        } else {
          onValueChange(workflowSelected[0]?.workflowId);
        }
      }}
      inputProps={{ name, value }}
      defaultSelected={data.filter((x) => x.workflowId === defaultValue)}
      size="lg"
      renderInput={({ inputRef, referenceElementRef, ...inputProperties }, state) => (
        <Hint>
          <FormGroup floating>
            <input
              {...inputProperties /* eslint-disable-line react/jsx-props-no-spreading */}
              id={id}
              className={`form-control ${className || ''} ${(isInvalid && touched) ? 'is-invalid' : ''}`}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
              onBlur={(event) => {
                state.hideMenu();
                setTouched(true);
                if (onBlur) {
                  onBlur(event);
                }
                return event;
              }}
            />
            <Label for={id}>Workflow</Label>
          </FormGroup>
        </Hint>
      )}
    />
  );
}

export default WorkflowsSelect;
