import { useGetSelfProfile, usePatchSelfProfile } from 'apiHooks/Users.Hook';
import Loading from 'components/shared/Loading/Loading';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import type { SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from 'reactstrap';
import { RANKS, ROLES } from 'utils/Constants';
import { userJwtToken } from 'utils/Helpers';

interface IEditProfileModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<SetStateAction<boolean>>;
  onClick: React.MouseEventHandler<HTMLElement> | undefined;
}

function EditProfileModal(props: IEditProfileModal) {
  const userToken = userJwtToken();
  const isAdmin = userToken?.user.claims.includes('admin');

  const { modalOpen, setModalOpen, onClick } = props;

  const [givenName, setGivenName] = useState<string>(userToken?.user.names.givenName || '');
  const [familyName, setFamilyName] = useState<string>(userToken?.user.names.familyName || '');
  const [rank, setRank] = useState<string>(userToken?.user.rank || '');

  const {
    data: userProfile,
    isLoading,
    isError,
    error,
  } = useGetSelfProfile(modalOpen);

  const patchSelfProfile = usePatchSelfProfile();

  useEffect(() => {
    if (userProfile?.rank) {
      setGivenName(userProfile.gname);
      setFamilyName(userProfile.fname);
      setRank(userProfile.rank);
    }
  }, [userProfile]);

  if (isError) return error as string;
  if (!userToken) return 'No user token found.';

  const canSubmit = (
    !!userProfile
    && givenName.length <= 50
    && familyName.length <= 50
    && (
      userProfile.gname !== givenName
      || userProfile.fname !== familyName
      || userProfile.rank !== rank
    )
  );

  const onSubmit = () => patchSelfProfile({
    ...givenName !== userProfile?.gname && { gname: givenName },
    ...familyName !== userProfile?.fname && { fname: familyName },
    ...rank !== userProfile?.rank && { rank },
  });

  return (
    <>

      <DropdownItem
        onClick={(e) => {
          onClick?.(e);
          setModalOpen(true);
        }}
        className="rounded"
      >
        Profile
      </DropdownItem>

      <RhinoModal
        title={`${isAdmin ? 'Edit ' : ''} Profile`}
        size="sm"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit}
        onSubmit={isAdmin ? onSubmit : undefined}
        bodyClassName="d-flex flex-column align-items-center"
      >
        {isLoading
          ? (
            <Loading />
          ) : (
            <div className="d-flex flex-column gap-3">

              <Input
                placeholder="Given Name"
                value={givenName}
                onChange={(e) => setGivenName(e.target.value)}
                disabled={!isAdmin}
              />
              <Input
                placeholder="Family Name"
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
                disabled={!isAdmin}
              />

              <UncontrolledDropdown disabled={!isAdmin}>
                <DropdownToggle
                  disabled={!isAdmin}
                  caret
                  className="form-control bg-white text-dark border border-muted"
                >
                  {`Rank: ${rank}`}
                </DropdownToggle>
                <DropdownMenu className="w-100 text-center">
                  {RANKS.map((r) => (
                    <DropdownItem key={r} onClick={() => setRank(r)}>
                      {r}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>

              <span className="px-3 text-center">
                {`Role: ${userProfile?.role ? ROLES[userProfile?.role] : 'None'}`}
              </span>
            </div>
          )}
        <Collapse isOpen={canSubmit} className="text-muted text-center">
          <div className="mt-3 pt-2 border-top">
            Name and rank will update everywhere upon next login.
          </div>
        </Collapse>
      </RhinoModal>
    </>

  );
}

export default EditProfileModal;
