import React from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { formatNameAndRank } from 'utils/Helpers';

/**
 * AgGrid Value formatter for date/times
 *
 * @param params
 * @returns
 */
function AgGridFormatNameAndRankCellRenderer({ value }: ICellRendererParams) {
  const nameString = formatNameAndRank(value);

  if (!value?.active) {
    return (
      <span className="text-muted">
        {nameString}
        { ' (Inactive)' }
      </span>
    );
  }

  return <span>{nameString}</span>;
}

export default AgGridFormatNameAndRankCellRenderer;
