import React from 'react';
import { FormGroup, Label, Spinner } from 'reactstrap';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';

import { useGetOrganizations } from 'apiHooks/Organizations.Hook';
import type { IOrganizations } from 'types/IOrganizations';
import type { ITypeAheadReactHookForm } from 'types/ITypeAheadReactHookForm';
import customFilterBy from 'utils/helpers/customFilterBy.Helper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function OrganizationsSelect({
  className,
  id,
  onValueChange,
  onBlur,
  name,
  formRef,
  defaultValue,
  value,
  isInvalid,
}: ITypeAheadReactHookForm<IOrganizations | undefined>) {
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetOrganizations();

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return (
    <Typeahead
      filterBy={customFilterBy<IOrganizations>('name')}
      id={id}
      className={className || ''}
      options={data}
      highlightOnlyResult
      labelKey={(org) => (org as IOrganizations).name}
      placeholder=" "
      onChange={(selected) => {
        if (!onValueChange) {
          return;
        }
        const orgSelected = selected as IOrganizations[];
        if (orgSelected.length === 0) {
          onValueChange(undefined);
        } else {
          onValueChange(orgSelected[0] as IOrganizations);
        }
      }}
      inputProps={{ name, value }}
      defaultSelected={data.filter((x) => x.id === defaultValue)}
      ref={formRef}
      size="lg"
      isInvalid={isInvalid || false}
      renderInput={({ inputRef, referenceElementRef, ...inputProperties }) => (
        <Hint>
          <FormGroup floating>
            <input
              {...inputProperties /* eslint-disable-line react/jsx-props-no-spreading */}
              className={`form-control ${className || ''} ${isInvalid ? 'is-invalid' : ''}`}
              id={id}
              onBlur={(event) => onBlur && onBlur(event)}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
            />
            <Label for={id}>Organization</Label>
          </FormGroup>
        </Hint>
      )}
    />
  );
}

export default OrganizationsSelect;
