import { useQuery } from '@tanstack/react-query';
import { authHttpGet } from 'store/authHttp.store';
import type IMonthlyReport from 'types/IReport';
import { userOrganizationId } from 'utils/Helpers';

const formatUrlParams = (
  startDate?: Date,
  endDate?: Date,
) => {
  const startDateParam = startDate ? `startDate=${startDate.toISOString()}` : '';
  const endDateParam = endDate ? `endDate=${endDate.toISOString()}` : '';

  return [
    '?useOverview=false',
    startDateParam,
    endDateParam,
  ].filter(Boolean).join('&');
};

const useGetMonthlyReports = (startDate?: Date, endDate?: Date) => (
  useQuery({
    queryKey: ['GetMonthlyReports', startDate, endDate],
    queryFn: () => authHttpGet<IMonthlyReport[]>(
      `/data/v1/organizations/${userOrganizationId()}/reports/monthly${formatUrlParams(startDate, endDate)}`,
    ),
    retry: false,
  })
);

export default useGetMonthlyReports;
