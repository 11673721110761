import { useQuery, useQueryClient } from '@tanstack/react-query';

import { userOrganizationId } from 'utils/Helpers';
import type {
  IContactPatch,
  IOrganization,
  IOrganizationShiftsPut,
  IShiftDay,
} from 'types/IOrganization';
import { authHttpGet, authHttpPatch } from 'store/authHttp.store';

const useGetOrganization = () => (
  useQuery<IOrganization>({
    queryKey: ['GetOrganization'],
    queryFn: () => {
      const organizationId = userOrganizationId();
      return authHttpGet(`/data/v1/organizations/${organizationId}`);
    },
  })
);

const useGetOrganizationShiftTimes = (enabled: boolean) => (
  useQuery({
    queryKey: ['GetOrganizationShiftTimes'],
    queryFn: () => authHttpGet<IShiftDay[]>(
      `/data/v1/organizations/${userOrganizationId()}/shifts`,
    ),
    enabled,
  })
);

const usePatchOrganizationLeadTimes = () => {
  const queryClient = useQueryClient();

  return (leadTimes: Record<string, number | undefined>) => (
    authHttpPatch(`/data/v1/organizations/${userOrganizationId()}`, leadTimes)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }))
      .catch(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }))
  );
};

const usePutOrganizationShiftTimes = () => {
  const queryClient = useQueryClient();

  return (shiftsPut: IOrganizationShiftsPut) => (
    // this PATCH is an alias for a PUT
    authHttpPatch(`/data/v1/organizations/${userOrganizationId()}/shifts`, shiftsPut)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetOrganizationShiftTimes'] }))
  );
};

const usePatchOrganizationContactInfo = () => {
  const queryClient = useQueryClient();

  return (contactPatch: IContactPatch) => (
    authHttpPatch('/data/v2/organization', contactPatch)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetOrganization'] }))
  );
};

export {
  useGetOrganization,
  useGetOrganizationShiftTimes,
  usePatchOrganizationLeadTimes,
  usePutOrganizationShiftTimes,
  usePatchOrganizationContactInfo,
};
