import { atomWithReset } from 'jotai/utils';

import type { ICustomFilter } from 'types/IFilter';
import {
  FILTER_TYPES,
  ROLES,
  ROLE_LIST,
  RANKS,
} from 'utils/Constants';
import type { UserAdminField } from './UserAdminTableColumns';

const USER_ADMIN_INITIAL_FILTERS: ICustomFilter<UserAdminField> = {
  gname: { // cSpell: disable-line
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  fname: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  email: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  rank: {
    options: [...RANKS],
    labels: RANKS,
    filterValues: RANKS.map(() => true),
    isHidden: false,
    filterType: FILTER_TYPES.SET,
  },
  role: {
    options: [...ROLE_LIST],
    labels: ROLE_LIST.map((role) => ROLES[role] || role),
    filterValues: ROLE_LIST.map(() => true),
    isHidden: false,
    filterType: FILTER_TYPES.SET,
  },
  sectionId: {
    options: [],
    labels: [],
    filterValues: [],
    isHidden: false,
    filterType: FILTER_TYPES.SET,
  },
  claims: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, true],
    isHidden: false,
  },
  active: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, false],
    isHidden: false,
  },
};

const UserAdminFilterAtom = atomWithReset<ICustomFilter<UserAdminField>>(
  USER_ADMIN_INITIAL_FILTERS,
);

const UserAdminInitialFilterAtom = atomWithReset<ICustomFilter<UserAdminField>>(
  USER_ADMIN_INITIAL_FILTERS,
);

export {
  UserAdminFilterAtom,
  UserAdminInitialFilterAtom,
};
