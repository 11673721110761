const formatTime = (
  date: Date | null | undefined,
  options?: Intl.DateTimeFormatOptions,
) => {
  if (!date) return '';

  const formatted = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
    timeZone: 'UTC',
    ...options,
  });

  return (
    formatted === 'Invalid Date'
      ? ''
      : formatted
  );
};

export default formatTime;
