import type { IUser } from 'types/IUser';

const formatNameAndRank = <U extends IUser>(user: U | undefined) => {
  if (!user) {
    return '';
  }

  return [
    user.rank || '',
    user.gname || '', // cSpell: disable-line
    user.fname || '',
  ].filter(Boolean).join(' ').trim();
};

export default formatNameAndRank;
