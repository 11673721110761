import {
  useQuery,
  useQueries,
  useQueryClient,
  type QueryClient,
} from '@tanstack/react-query';

import { userOrganizationId, userJwtToken } from 'utils/Helpers';
import type {
  AgGridUser,
  INewUser,
  IUser,
  IUserDetails,
  IUserProfile,
  UserSetting,
  UserSettings,
  UserSettingsField,
} from 'types/IUser';
import { authHttpGet, authHttpPatch, authHttpPost } from 'store/authHttp.store';

/**
 *
 * @returns
 */
const organizationUsersUrl = () => `/data/v1/organizations/${userOrganizationId()}/users`;

/**
 *
 */
const getUsersQuery = {
  queryKey: ['GetUsers'],
  queryFn: () => authHttpGet<IUserDetails[]>(organizationUsersUrl()),
};

/**
 *
 * @returns
 */
const useGetUsers = () => useQuery(getUsersQuery);

/**
 *
 */
const getUserProfilesQuery = {
  queryKey: ['GetUsersProfiles'],
  queryFn: () => (authHttpGet<IUserProfile[]>('/auth/v1/profiles')),
};

/**
 *
 * @param enabled
 * @returns
 */
const useGetUserProfiles = (enabled: boolean = true) => useQuery({
  ...getUserProfilesQuery,
  enabled,
});

/**
 *
 * @param enabled
 * @returns
 */
const useGetSelfProfile = (enabled: boolean = true) => useQuery({
  queryKey: ['GetSelfProfile'],
  queryFn: () => (authHttpGet<IUserProfile>('/auth/v1/profiles/self')),
  enabled,
});

const useGetUsersAndProfiles = () => (
  useQueries({
    queries: [
      getUsersQuery,
      getUserProfilesQuery,
    ],
  })
);

/**
 *
 * @returns
 */
const useGetUserSettings = () => useQuery({
  queryKey: ['GetUserSettings'],
  queryFn: () => authHttpGet<UserSettings>(
    `/auth/v1/profiles/${userJwtToken()?.user.id}/settings`,
  ),
});

/**
 *
 * @param queryClient
 */
const resetProfileQueries = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ['GetUsersProfiles'] }).catch(() => {});
  queryClient.invalidateQueries({ queryKey: ['GetSelfProfile'] }).catch(() => {});
};

/**
 *
 * @returns
 */
const usePatchUserProfile = () => {
  const queryClient = useQueryClient();

  return (userPatch: AgGridUser) => (
    authHttpPatch(
      `/auth/v1/profiles/${userPatch.userId}`,
      userPatch,
    )
  )
    .then(() => resetProfileQueries(queryClient))
    .catch(() => resetProfileQueries(queryClient));
};

/**
 *
 * @returns
 */
const usePatchUserData = () => {
  const queryClient = useQueryClient();

  return (userPatch: Pick<AgGridUser, 'sectionId' | 'active' | 'userId'>) => {
    const { userId, sectionId, active } = userPatch;

    return authHttpPatch(
      `${organizationUsersUrl()}/${userId}`,
      { sectionId, active },
    )
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetUsers'] }))
      .catch(() => {});
  };
};

/**
 *
 * @returns
 */
const usePatchUser = () => {
  const patchUserProfile = usePatchUserProfile();
  const patchUserData = usePatchUserData();

  return (userPatch: AgGridUser) => Promise.all([
    patchUserProfile(userPatch),
    patchUserData(userPatch),
  ]);
};

/**
 *
 * @returns
 */
const usePatchSelfProfile = () => {
  const queryClient = useQueryClient();

  const userToken = userJwtToken();

  return (userPatch: Partial<IUser>) => (
    authHttpPatch(`/auth/v1/profiles/${userToken?.user.id}`, userPatch)
      .then(() => resetProfileQueries(queryClient))
      .catch(() => resetProfileQueries(queryClient))
  );
};

/**
 * PATCH a single user setting
 */
const usePatchUserSetting = () => {
  const queryClient = useQueryClient();
  const userToken = userJwtToken();
  const userId = userToken?.user.id;

  // const queryClient = useQueryClient();

  type SettingUpdate<F extends UserSettingsField = UserSettingsField> = {
    field: F;
    value: UserSetting<F>;
  };

  return ({ field, value }: SettingUpdate) => (
    authHttpPatch(
      `/auth/v1/profiles/${userId}/settings`,
      [{
        op: 'add',
        path: `/${field}`,
        value,
      }],
    )
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetUserSettings'] }))
    .catch(() => {});
};

/**
 * PATCH multiple user settings
 */
const usePatchUserSettings = () => {
  const userToken = userJwtToken();
  const userId = userToken?.user.id;

  type SettingUpdate<F extends UserSettingsField = UserSettingsField> = {
    field: F;
    value: UserSetting<F>;
  };

  return (settings: SettingUpdate[]) => (
    authHttpPatch(
      `/auth/v1/profiles/${userId}/settings`,
      settings.map((setting) => ({
        op: 'add',
        path: `/${setting.field}`,
        value: setting.value,
      })),
    )
  )
    .catch(() => {});
};

/**
 *
 * @returns
 */
const usePostUser = () => {
  const queryClient = useQueryClient();

  return (userPost: INewUser) => (
    authHttpPost<Record<string, string>>('/auth/v1/profiles/', userPost)
  ).then(async (response) => {
    const { userId } = response;
    await authHttpPost(organizationUsersUrl(), { id: userId, section: userPost.section });
    return Promise.all([
      queryClient.invalidateQueries({ queryKey: ['GetUsers'] }),
      queryClient.invalidateQueries({ queryKey: ['GetUsersProfiles'] }),
    ]);
  });
};

export {
  useGetUsers,
  useGetUserProfiles,
  useGetSelfProfile,
  useGetUsersAndProfiles,
  useGetUserSettings,

  usePatchUser,
  usePatchSelfProfile,
  usePatchUserSetting,
  usePatchUserSettings,

  usePostUser,
};
