import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import type {
  ColDef,
} from '@ag-grid-community/core';

import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import type { IEolEntry } from 'types/IOverview';

function ApproachingEol({ rows }: { rows: IEolEntry[] }) {
  const [columnDefs] = React.useState<ColDef<IEolEntry>[]>([
    {
      field: 'regNumber',
      headerName: 'Reg Number',
    },
    {
      field: 'vehicleType',
      headerName: 'Type',
    },
    {
      field: 'eolDate',
      headerName: 'EOL Date',
      valueFormatter: AgGridDateValueFormatter,
    },
    {
      field: 'daysToEol',
      headerName: 'Days to EOL',
    },
  ]);

  return (
    <div className="ag-theme-material w-100 h-100">
      <AgGridReact
        rowData={rows}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
      />
    </div>
  );
}

export default ApproachingEol;
