import { useGetVehicleMxPmi } from 'apiHooks/Vehicles.Hook';
import React, { useEffect } from 'react';
import {
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import type {
  ColDef, ColumnApi, GridReadyEvent, ValueGetterParams,
} from '@ag-grid-community/core';
import type { IPmi } from 'types/Mx';
import { AgGridReact } from '@ag-grid-community/react';
import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import { differenceInDays } from 'date-fns';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';
import AgGridEditPmiMxCellRenderer from './AgGridEditPmiCellRenderer';
import CreatePmiModal from './CreatePmiModal';

interface IPmiCols extends IPmi {
  daysAway: number,
  edit: unknown,
}

const PMI_MX_COLUMNS_COL_DEFS: (restrictUpdates: boolean) => ColDef<IPmiCols>[] = (
  (restrictUpdates) => [
    {
      field: 'lastModificationDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Last Modified',
      width: 250,
    }, {
      field: 'mxDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Scheduled For',
      width: 250,
    }, {
      field: 'daysAway',
      valueGetter: (params: ValueGetterParams<IPmiCols>) => (
        (params.data?.mxDate && !params.data.completionDate)
          ? differenceInDays(new Date(params.data.mxDate), new Date())
          : ''
      ),
      headerName: 'Days Away',
      width: 120,
    }, {
      field: 'completionDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Completed On',
      initialHide: true,
      width: 250,
    }, {
      field: 'edit',
      headerName: '',
      cellRenderer: AgGridEditPmiMxCellRenderer,
      width: 100,
      hide: restrictUpdates,
    },
  ]
);

export default function VehicleMaintenancePmi({ regNumber }: { regNumber: string }) {
  const restrictUpdates = useRestrictUpdates();

  const [columnDefs] = React.useState(PMI_MX_COLUMNS_COL_DEFS(restrictUpdates));
  const [columnApi, setColumnApi] = React.useState<ColumnApi>();
  const [showHistoric, setShowHistoric] = React.useState(false);
  const [currentRowData, setCurrentRowData] = React.useState<IPmi[]>();

  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetVehicleMxPmi(regNumber);

  useEffect(() => {
    setCurrentRowData(data?.filter((x) => !x.completed));
  }, [data]);

  const onGridReady = (params: GridReadyEvent) => {
    setColumnApi(params.columnApi);
  };

  const toggleShowHistoric = () => {
    columnApi?.setColumnsVisible(['completionDate'], !showHistoric);
    setShowHistoric((b) => !b);
  };

  if (isLoading || !data) return <Spinner />;
  if (isError) return <span>{error as string}</span>;

  return (
    <div className="d-flex flex-column h-100">
      <div className="w-100 d-flex p-2 align-items-center justify-content-between border-bottom">
        <Label>
          <span className="px-2">Show Completed PMI:</span>
          <Input
            defaultChecked={showHistoric}
            onClick={toggleShowHistoric}
            type="checkbox"
          />
        </Label>
        {!restrictUpdates && <CreatePmiModal regNumber={regNumber} />}
      </div>

      <div className="ag-theme-material w-100 h-100">
        <AgGridReact
          rowData={(showHistoric ? data : (currentRowData || []))}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          animateRows
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
        />
      </div>
    </div>
  );
}
