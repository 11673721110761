import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import type {
  ColDef,
} from '@ag-grid-community/core';

import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import type { IWashSubmission } from 'types/IOverview';

function ApproachingWashes({ rows }: { rows: IWashSubmission[] }) {
  const [columnDefs] = React.useState<ColDef[]>([
    {
      field: 'regNumber',
      headerName: 'Reg Number',
    },
    {
      field: 'date',
      headerName: 'Date',
      valueFormatter: AgGridDateValueFormatter,
    },
    {
      field: 'daysToWash',
      headerName: 'Days to wash',
    },
  ]);

  return (
    <div className="ag-theme-material w-100 h-100">
      <AgGridReact
        rowData={rows}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
      />
    </div>
  );
}

export default ApproachingWashes;
