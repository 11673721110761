import type { ColDef } from '@ag-grid-community/core';
import { atomWithReset } from 'jotai/utils';

import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import AgGridFormatNameAndRankCellRenderer from 'components/agGridRenderers/AgGridFormatNameAndRankCellRenderer';
import AgGridHtmlDecodeValueFormatter from 'components/agGridRenderers/AgGridHtmlDecodeValueFormatter';
import AgGridUserNameResponseComparator from 'components/agGridRenderers/AgGridUserNameResponseComparator';
import AgGridBooleanIconCellRenderer from 'components/agGridRenderers/AgGridBooleanIconCellRenderer';

import AgGridWorkflowListActionsCellRenderer from 'views/InspectionWorkflows/AgGridWorkflowListActionsCellRenderer';
import type { IWorkflowDetails } from 'types/IWorkflows';

const INSPECTION_WORKFLOWS_FIELDS = [
  'title',
  'validFrom',
  'lastModifierName',
  'active',
  'actions',
] as const;

export type InspectionWorkflowField = typeof INSPECTION_WORKFLOWS_FIELDS[number];

const INSPECTION_WORKFLOWS_FIELD_HEADER_MAP: Record<InspectionWorkflowField, string> = {
  title: 'Name',
  validFrom: 'Last Modified',
  lastModifierName: 'Last Modified By',
  active: 'Show Inactive',
  actions: '',
};

const initialColumns: ColDef<IWorkflowDetails & { actions: unknown; }>[] = (
  INSPECTION_WORKFLOWS_FIELDS.map((field) => {
    const headerName = INSPECTION_WORKFLOWS_FIELD_HEADER_MAP[field] || '';

    if (field === 'validFrom') {
      return {
        field,
        headerName,
        valueFormatter: AgGridDateValueFormatter,
      };
    }

    if (field === 'lastModifierName') {
      return {
        field,
        headerName,
        cellRenderer: AgGridFormatNameAndRankCellRenderer,
        comparator: AgGridUserNameResponseComparator,
      };
    }

    if (field === 'active') {
      return {
        field: 'active',
        headerName: 'Is Active',
        sortable: false,
        resizable: false,
        width: 100,
        cellRenderer: AgGridBooleanIconCellRenderer,
      };
    }

    if (field === 'actions') {
      return {
        field,
        headerName,
        sortable: false,
        resizable: true,
        width: 100,
        cellRenderer: AgGridWorkflowListActionsCellRenderer,
      };
    }

    return {
      field,
      headerName,
      cellRenderer: AgGridHtmlDecodeValueFormatter,
    };
  })
);

const InspectionWorkflowsAgGridColDefsAtom = atomWithReset<ColDef[]>(initialColumns);

export {
  InspectionWorkflowsAgGridColDefsAtom,
  INSPECTION_WORKFLOWS_FIELD_HEADER_MAP,
  INSPECTION_WORKFLOWS_FIELDS,
};
