import React from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupText,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';

import RanksSelect from 'components/fields/RankSelect';
import RolesSelect from 'components/fields/RoleSelect';
import OrganizationSectionsSelect from 'components/fields/OrganizationSectionsSelect';
import TogglePasswordIcon from 'components/fields/TogglePasswordIcon';
import { usePostUser } from 'apiHooks/Users.Hook';
import type { INewUser } from 'types/IUser';
import { ROLE_REQUIRING_SECTION } from 'utils/Constants';
import { userOrganizationName, useRestrictUpdates } from 'utils/Helpers';
import type { IZxcvbn } from 'types/zxcvbn';

import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { type AxiosError } from 'axios';
import styles from './AddNewUser.module.css';

const passwordVisibilityClasses = 'mb-3 bg-white border-start-0 rounded-end toggle-pw-visibility';
const errorClass = `is-invalid ${styles['no-blur'] || ''}`;

interface IAddUserErrors {
  formErrors: string[],
  addUserErrors: string | undefined,
}

function AddUserErrors({ formErrors, addUserErrors }: IAddUserErrors) {
  if (formErrors.length === 0 && !addUserErrors) {
    return <div />;
  }

  if (formErrors.length === 1 && formErrors[0] === 'confirmPassword') {
    return <Alert className="mb-0 text-center" color="danger">Passwords do not match</Alert>;
  }

  if (addUserErrors) {
    try {
      const { errorMessage } = JSON.parse(addUserErrors) as Record<string, IZxcvbn>;
      const zxcvbnResponse = errorMessage as IZxcvbn;
      return (
        <Alert className="mb-0 text-center" color="danger">
          {`${zxcvbnResponse.warning || 'Weak Password'}. ${zxcvbnResponse.suggestions.join(' ')}`}
        </Alert>
      );
    } catch {
      try {
        const { errorMessage } = JSON.parse(addUserErrors) as Record<string, string>;
        return (
          <Alert className="mb-0 text-center" color="danger">
            {errorMessage}
          </Alert>
        );
      } catch {
        return <Alert className="mb-0 text-center" color="danger">{addUserErrors.replaceAll('"', '')}</Alert>;
      }
    }
  }

  return <div />;
}

function AddUserModal() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [addUserErrors, setAddUsersErrors] = React.useState('');
  const postUser = usePostUser();

  const toggle = () => setModalOpen(!modalOpen);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<INewUser>({
    mode: 'onBlur',
    defaultValues: {
      active: true,
      claims: [],
    },
  });

  const cancelAddSectionName = () => {
    toggle();
  };

  const restrictUpdates = useRestrictUpdates({ requireAdmin: true });

  // NOTE: org section, rank and role are registered through react-hook-form's control
  const givenNameFormRegistration = register('gname', { required: true }); // cSpell: disable-line
  const familyNameFormRegistration = register('fname', { required: true }); // cSpell: disable-line
  const emailFormRegistration = register('email', { required: true });
  const passwordFormRegistration = register('password', { required: true });
  const confirmPasswordFormRegistration = register(
    'confirmPassword',
    {
      required: true,
      validate: (confirmPassword: string | undefined) => (watch('password') === confirmPassword),
    },
  );
  const activeFormRegistration = register('active');
  const adminFormRegistration = register('claims');

  const onSubmit = (formData: INewUser) => {
    postUser(formData).then(toggle).catch((error: AxiosError) => {
      if (!error || !error.response || !error.response.data) {
        return;
      }
      const { errorMessage } = error.response.data as Record<string, unknown>;

      if (typeof errorMessage === 'string') {
        setAddUsersErrors(errorMessage);
      } else {
        setAddUsersErrors(JSON.stringify({ errorMessage }));
      }
    });
  };

  return (
    <>
      <Button disabled={restrictUpdates} onClick={toggle}>Add User</Button>
      <Modal isOpen={modalOpen} toggle={cancelAddSectionName}>
        <Form
          className="bg-white container-lg g-0 rounded"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <RhinoModalHeader toggle={toggle}>
            Add user to
            {' '}
            { userOrganizationName() }
          </RhinoModalHeader>
          <ModalBody>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors['gname'] ? errorClass : '')}`} // cSpell: disable-line
                id="inputGivenNameInput"
                placeholder=" "
                autoComplete="off"
                onChange={givenNameFormRegistration.onChange}
                onBlur={givenNameFormRegistration.onBlur}
                name={givenNameFormRegistration.name}
                ref={givenNameFormRegistration.ref}
              />
              <Label htmlFor="inputGivenNameInput">First/Given Name</Label>
            </FormGroup>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors['fname'] ? errorClass : '')}`} // cSpell: disable-line
                id="inputFamilyNameInput"
                autoComplete="off"
                placeholder=" "
                onChange={familyNameFormRegistration.onChange}
                onBlur={familyNameFormRegistration.onBlur}
                name={familyNameFormRegistration.name}
                ref={familyNameFormRegistration.ref}
              />
              <Label htmlFor="inputFamilyNameInput">Last/Family Name</Label>
            </FormGroup>
            <Controller
              control={control}
              name="rank"
              rules={{ required: true }}
              render={({
                field,
                fieldState,
              }) => (
                <RanksSelect
                  id="inputRankSelect"
                  onValueChange={(selected) => {
                    if (selected && selected.value) {
                      field.onChange(selected.value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  onBlur={field.onBlur}
                  className="shadow-sm"
                  name={field.name}
                  formRef={field.ref}
                  isInvalid={fieldState.invalid}
                  defaultValue={field.value || undefined}
                />
              )}
            />
            <Controller
              control={control}
              name="role"
              rules={{ required: true }}
              render={({
                field,
                fieldState,
              }) => (
                <RolesSelect
                  id="inputRoleSelect"
                  onValueChange={(selected) => {
                    if (selected && selected.value) {
                      field.onChange(selected.value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  onBlur={field.onBlur}
                  className="shadow-sm"
                  name={field.name}
                  formRef={field.ref}
                  isInvalid={fieldState.invalid}
                  defaultValue={field.value || undefined}
                />
              )}
            />
            <Controller
              control={control}
              name="section"
              rules={{
                validate: (sectionId: number | undefined | null) => {
                  if (!watch('role')) {
                    return false;
                  }
                  return Number.isFinite(sectionId) || !ROLE_REQUIRING_SECTION.includes(watch('role') || '');
                },
              }}
              render={({
                field,
                fieldState,
              }) => (
                <OrganizationSectionsSelect
                  id="inputOrganizationSectionSelect"
                  onBlur={field.onBlur}
                  onValueChange={field.onChange}
                  name={field.name}
                  formRef={field.ref}
                  defaultValue={field.value || undefined}
                  isInvalid={fieldState.invalid}
                />
              )}
            />
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors.email ? errorClass : '')}`}
                id="inputEmailInput"
                autoComplete="off"
                placeholder=" "
                onChange={emailFormRegistration.onChange}
                onBlur={emailFormRegistration.onBlur}
                name={emailFormRegistration.name}
                ref={emailFormRegistration.ref}
              />
              <Label htmlFor="inputEmailInput">Email address</Label>
            </FormGroup>
            <InputGroup size="sm" className="mb-3">
              <FormGroup floating className="shadow-sm">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control border-end-0 ${(errors.password ? errorClass : '')}`}
                  autoComplete="new-password"
                  placeholder=" "
                  id="inputPassword"
                  onChange={passwordFormRegistration.onChange}
                  onBlur={passwordFormRegistration.onBlur}
                  name={passwordFormRegistration.name}
                  ref={passwordFormRegistration.ref}
                />
                <Label htmlFor="inputPassword">Password</Label>
              </FormGroup>
              <InputGroupText
                className={`${passwordVisibilityClasses}  ${(errors.password ? 'border-danger' : '')}`}
                onClick={() => setShowPassword(!showPassword)}
                title="Show/Hide password"
              >
                <TogglePasswordIcon showPassword={showPassword} />
              </InputGroupText>
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <FormGroup floating className="shadow-sm">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control border-end-0 ${(errors.confirmPassword || errors.password ? errorClass : '')}`}
                  autoComplete="new-password"
                  id="inputConfirmPassword"
                  placeholder=" "
                  onChange={confirmPasswordFormRegistration.onChange}
                  onBlur={confirmPasswordFormRegistration.onBlur}
                  name={confirmPasswordFormRegistration.name}
                  ref={confirmPasswordFormRegistration.ref}
                />
                <Label htmlFor="inputConfirmPassword">Confirm Password</Label>
              </FormGroup>
              <InputGroupText
                className={`${passwordVisibilityClasses} ${(errors.confirmPassword || errors.password ? 'border-danger' : '')}`}
                onClick={() => setShowPassword(!showPassword)}
                title="Show/Hide password"
              >
                <TogglePasswordIcon showPassword={showPassword} />
              </InputGroupText>
            </InputGroup>
            <div className="d-flex justify-content-between mb-3">
              <FormGroup
                check
                inline
              >
                <Label check>
                  <input
                    type="checkbox"
                    onChange={activeFormRegistration.onChange}
                    onBlur={activeFormRegistration.onBlur}
                    name={activeFormRegistration.name}
                    ref={activeFormRegistration.ref}
                  />
                  Active
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
              >
                <Label check>
                  <input
                    type="checkbox"
                    value="admin"
                    onChange={adminFormRegistration.onChange}
                    onBlur={adminFormRegistration.onBlur}
                    name={adminFormRegistration.name}
                    ref={adminFormRegistration.ref}
                  />
                  Admin
                </Label>
              </FormGroup>
            </div>
            <AddUserErrors
              formErrors={Object.keys(errors)}
              addUserErrors={addUserErrors}
            />
            {
              Object.keys(errors || {}).includes('section')
              && <Alert className="mb-0 text-center" color="danger">The selected role requires and organization</Alert>
            }
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button color="secondary" onClick={cancelAddSectionName}>
              Cancel
            </Button>
            <input
              className="btn btn-primary text-dark"
              disabled={(!errors || (Object.keys(errors)).length > 0)}
              type="submit"
              value="Add User"
            />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default AddUserModal;
