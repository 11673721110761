import React from 'react';
import { FormGroup, Label, Spinner } from 'reactstrap';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';

import type { IOrganizationSections } from 'types/IOrganizationSections';
import type { ITypeAheadReactHookForm } from 'types/ITypeAheadReactHookForm';
import { useGetOrganizationSections } from 'apiHooks/OrganizationSections.Hook';
import customFilterBy from 'utils/helpers/customFilterBy.Helper';

function OrganizationSectionsSelect({
  className,
  id,
  onValueChange,
  onBlur,
  name,
  formRef,
  defaultValue,
  value,
  isInvalid,
} : ITypeAheadReactHookForm) {
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetOrganizationSections();
  const [touched, setTouched] = React.useState(false);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  return (
    <Typeahead
      filterBy={customFilterBy<IOrganizationSections>('sectionName')}
      id={id}
      className={className || ''}
      options={data}
      placeholder=""
      highlightOnlyResult
      selectHint={(shouldSelect, event) => (event.key === 'Enter' || shouldSelect)}
      onChange={(selected) => {
        if (!onValueChange) {
          return;
        }
        const orgSelected = selected as IOrganizationSections[];
        if (orgSelected.length === 0) {
          onValueChange(undefined);
        } else {
          onValueChange(orgSelected[0]?.sectionId);
        }
      }}
      labelKey="sectionName"
      inputProps={{ name, value }}
      defaultSelected={data.filter((x) => x.sectionId === defaultValue)}
      ref={formRef}
      size="lg"
      renderInput={({ inputRef, referenceElementRef, ...inputProperties }, state) => (
        <Hint>
          <FormGroup floating>
            <input
              {...inputProperties /* eslint-disable-line react/jsx-props-no-spreading */}
              id={id}
              className={`form-control ${className || ''} ${(isInvalid && touched) ? 'is-invalid' : ''}`}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
              onBlur={(event) => {
                state.hideMenu();
                setTouched(true);
                if (onBlur) {
                  onBlur(event);
                }
              }}
            />
            <Label for={id}>Organization Section</Label>
          </FormGroup>
        </Hint>
      )}
    />
  );
}

export default OrganizationSectionsSelect;
