import { addMonths, differenceInMonths, isSameMonth } from 'date-fns';
import { saveAs } from 'file-saver';
import type { ITimeSeriesUsageDatum } from 'types/Insights';

type MonthyData = {
  month: string;
  data: ITimeSeriesUsageDatum[];
};

type MonthlyStats = {
  month: string;
  miles?: number;
  hours?: number;
};

type VehicleStats = {
  regNumber: string;
  statsByMonth: MonthlyStats[];
};

const formatDateForCSV = (date: Date) => date.toLocaleString('en-US', { month: 'short', year: 'numeric' });

const groupTimeSeriesByVehicle = (data: ITimeSeriesUsageDatum[]) => (
  data.reduce<Record<string, ITimeSeriesUsageDatum[]>>(
    (agg, curr) => {
      const vehicleSeries = agg[curr.regNumber];
      return ({
        ...agg,
        [curr.regNumber]: [
          ...vehicleSeries ?? [],
          {
            ...curr,
          },
        ],
      });
    },
    {},
  )
);

const getLastExistingMonthFinalDatum = (dataByMonth: MonthyData[], monthIndex: number) => {
  for (let i = monthIndex - 1; i >= 0; i -= 1) {
    const monthDatum = dataByMonth[i];
    const finalUsageDatum = monthDatum?.data.at(-1);

    if (finalUsageDatum) {
      return finalUsageDatum;
    }
  }
  return undefined;
};

const makeStatsByMonth = (dataByMonth: MonthyData[]) => dataByMonth.map<MonthlyStats>(
  (monthDatum, monthIndex) => {
    const { month, data: monthSeries } = monthDatum;
    const lastExistingMonthFinalDatum = getLastExistingMonthFinalDatum(dataByMonth, monthIndex);
    // dataByMonth[index - 1]?.data.at(-1);
    const thisMonthFinalDatum = monthSeries.at(-1);

    if (!(lastExistingMonthFinalDatum && thisMonthFinalDatum)) {
      return { month };
    }

    const miles = thisMonthFinalDatum.miles
      ? thisMonthFinalDatum.miles - (lastExistingMonthFinalDatum.miles ?? 0)
      : undefined;

    const hours = thisMonthFinalDatum.hours
      ? thisMonthFinalDatum.hours - (lastExistingMonthFinalDatum.hours ?? 0)
      : undefined;

    return {
      month,
      ...miles && { miles },
      ...hours && { hours },
    };
  },
);

const saveVehicleUsageAsCsv = (data: ITimeSeriesUsageDatum[]) => {
  // PREPROCESSING
  const oldestDateOverall = data?.[0]?.date
    ? new Date(data?.[0]?.date)
    : undefined;

  const tmpNewestDate = data?.at(-1)?.date;
  const newestDateOverall = tmpNewestDate
    ? new Date(tmpNewestDate)
    : undefined;

  // do not proceed without these dates
  if (!(oldestDateOverall && newestDateOverall)) return;

  const monthsInRange = differenceInMonths(newestDateOverall, oldestDateOverall);
  const initMonthDate = new Date(oldestDateOverall);
  initMonthDate.setDate(1);
  initMonthDate.setHours(0, 0, 0, 0);

  const monthDates: Date[] = [];
  for (let i = 0; i <= monthsInRange; i += 1) {
    monthDates.push(addMonths(initMonthDate, i));
  }
  /** Array of dates correspondiong with the months to include in the CSV */
  const MONTH_DATES = [...monthDates] as const;

  const startMonth = MONTH_DATES[0];
  const endMonth = MONTH_DATES.at(-1);

  if (!(startMonth && endMonth) || startMonth === endMonth) return;

  // Group data into series by vehicle
  const timeSeriesByVehicle = groupTimeSeriesByVehicle(data);

  const allRegNumbers = Object.keys(timeSeriesByVehicle).sort();

  // Construct total usage stats for this period
  const statsByVehicle = allRegNumbers.map<VehicleStats>(
    (regNumber) => {
      const series = timeSeriesByVehicle[regNumber];

      if (!series) {
        return { regNumber, statsByMonth: [] };
      }

      const oldestDatum = series?.at(0);
      const newestDatum = series?.at(-1);

      // require these dates to continue
      if (!(oldestDatum && newestDatum)) {
        return { regNumber, statsByMonth: [] };
      }

      const dataByMonth: MonthyData[] = MONTH_DATES.map(
        (month) => ({
          month: month.toISOString().slice(0, 7),
          data: series.filter(
            (datum) => isSameMonth(month, new Date(datum.date)),
          ),
        }),
      );

      const statsByMonth = makeStatsByMonth(dataByMonth);

      return {
        regNumber,
        statsByMonth,
      };
    },
  );

  // construct CSV

  const reportHeaders = [
    'Registration Number',

    ...MONTH_DATES.flatMap<string>(
      (monthDate) => {
        const monthString = formatDateForCSV(monthDate);
        return [`${monthString} miles`, `${monthString} hours`];
      },
    ),
  ];

  const reportHeadersCSV = `"${reportHeaders.map((h) => h.replaceAll('"', '""')).join('","')}"`;

  const reportsEntriesCSV = statsByVehicle.map<string>(
    (vehicleStats) => {
      const statsFields = [
        `="${vehicleStats.regNumber}"`,

        ...vehicleStats.statsByMonth.flatMap(
          (monthStats) => [`${monthStats.miles ?? 0}`, `${monthStats.hours ?? 0}`],
        ),
      ];
      return `"${statsFields.map((h) => h.replaceAll('"', '""')).join('","')}"`;
    },
  );
  const reportCsvString = [reportHeadersCSV, ...reportsEntriesCSV].join('\n');
  const reportBlob = new Blob([reportCsvString]);
  saveAs(reportBlob, `vehicle-usage_${formatDateForCSV(startMonth)}-${formatDateForCSV(endMonth)}.csv`);
};

export default saveVehicleUsageAsCsv;
