import React from 'react';

import { userJwtToken, userOrganizationName } from 'utils/Helpers';
import type { IOrganization } from 'types/IOrganization';

import { LeadTimeFieldHeaderMap, ORG_LEAD_TIME_FIELDS } from 'utils/constants/Organization';
import EditOrganizationLeadTimesModal from './EditOrganizationLeadTimesModal';
import ShiftTimesModal from './ShiftTimesModal';
import EditContactInfoModal from './EditContactInfoModal';

import styles from './OrganizationSummary.module.css';

function OrganizationSummary({ orgDetails }: { orgDetails: IOrganization }) {
  const userToken = userJwtToken();

  return (
    <div className="d-flex flex-column bg-secondary px-4">
      <div className="d-flex align-items-end justify-content-between pt-4 pb-3">
        <h4 className="text-primary m-0">
          { userOrganizationName() }
          { ' ' }
          Options
        </h4>

        <div className="d-flex gap-4 align-items-end justify-content-end text-light">
          {userToken?.user.role === 'vco' && (
            <div className="d-flex gap-2">
              <ShiftTimesModal />
              <EditContactInfoModal org={orgDetails} />
            </div>
          )}
          <div className="d-flex gap-1 align-items-end rounded">
            Lead Time (days)
            <table className={styles['lead-times-table']}>
              <tr>
                {
                  ORG_LEAD_TIME_FIELDS.map((field) => (
                    <th className="text-center" key={field}>
                      {LeadTimeFieldHeaderMap[field]}
                    </th>
                  ))
                }
              </tr>
              <tr>
                {
                  ORG_LEAD_TIME_FIELDS.map((field) => (
                    <td key={field}>
                      {orgDetails[field]}
                    </td>
                  ))
                }
              </tr>
            </table>
            <EditOrganizationLeadTimesModal orgDetails={orgDetails} />
          </div>

        </div>
      </div>
    </div>
  );
}

export default OrganizationSummary;
