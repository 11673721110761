import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import type { ColDef } from '@ag-grid-community/core';

import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import type { IAllInOpStatus, IVehicleTypeAvailability, IWrmEntry } from 'types/IOverview';
import AgGridDateDeltaFormatter from 'components/agGridRenderers/AgGridDateDeltaFormatter';
import { decode } from 'html-entities';
import { Link } from 'react-router-dom';
import AgGridStatusOverviewStatusCellRenderer from './agGrid/AgGridStatusOverviewStatusCellRenderer';

interface IStatusOverview {
  inOpStatusRows: IAllInOpStatus[],
  availability: IVehicleTypeAvailability[],
  wrm: IWrmEntry[],
}

function StatusOverview({ inOpStatusRows, availability, wrm: wrmNotFmc }: IStatusOverview) {
  const [columnDefs] = React.useState<ColDef[]>([
    {
      field: 'regNumber',
      headerName: 'Reg Number',
    },
    {
      field: 'etic',
      headerName: 'ETIC',
      valueFormatter: AgGridDateValueFormatter,
    },
    {
      field: 'daysPast',
      headerName: 'Delta',
      cellRenderer: AgGridDateDeltaFormatter,
      initialSort: 'asc',
    },
    {
      field: '',
      headerName: '',
      cellRenderer: AgGridStatusOverviewStatusCellRenderer,
      width: 80,
    },
  ]);

  const typesBelowMelDesc = (
    availability.filter(
      (type) => !!type.mel && type.available - type.mel < 0,
    ) as Required<IVehicleTypeAvailability>[]
  ).toSorted((a, b) => (b.available / b.mel) - (a.available / a.mel));

  return (
    <div className="d-flex flex-column w-100 h-100">
      {
        typesBelowMelDesc.length > 0 && (
          <>
            <div className="p-2 gap-3 d-flex flex-row">
              <div className="w-25 text-end">Below MEL:</div>
              <div className="w-75 gap-1 d-flex flex-row flex-wrap">
                {
                  typesBelowMelDesc?.map(({ vehicleType, available, mel }) => (
                    <span key={vehicleType} className="d-flex gap-1 px-2">
                      <span className="fw-bold">
                        {decode(vehicleType)}
                      </span>
                      <span>
                        {`(${((
                          available / mel
                        ) * 100).toFixed(0)}%)`}
                      </span>
                    </span>
                  ))
                }
              </div>
            </div>

            <div className="border border-bottom border-1" />
          </>
        )
      }
      {
        wrmNotFmc.length > 0 && (
          <>
            <div className="p-2 gap-3 d-flex flex-row">
              <div className="w-25 text-end">WRM and not FMC:</div>
              <div className="w-75 gap-3 d-flex flex-row flex-wrap">
                {wrmNotFmc.map(({ regNumber }) => (
                  <Link
                    to={`/Vehicle/${regNumber}`}
                    key={regNumber}
                    className="text-danger"
                  >
                    {regNumber}
                  </Link>
                ))}
              </div>
            </div>

            <div className="border border-bottom border-1" />
          </>
        )
      }
      <div className="ag-theme-material w-100 h-100">
        <AgGridReact
          rowData={inOpStatusRows}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
        />
      </div>
    </div>
  );
}

export default StatusOverview;
