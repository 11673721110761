import React from 'react';
import { Button } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import type {
  CellDoubleClickedEvent,
  ColumnApi,
  CsvExportParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  ProcessCellForExportParams,
} from '@ag-grid-community/core';
import { MdFilterAlt } from 'react-icons/md';
import { useAtom } from 'jotai';
import isEqual from 'lodash-es/isEqual';

import { useGetFleet } from 'apiHooks/Vehicles.Hook';
import { FILTER_NUMBER_RANGE_TYPES, FILTER_TYPES } from 'utils/Constants';
import type { IFleetDetail } from 'types/IFleetDetails';
import type {
  IColumnFilter,
  IColumnFilterText,
  IColumnFilterDateTime,
  IColumnFilterNumberRange,
  IColumnFilterSet,
  IDateTimeFilter,
  IColumnFilterBoolean,
} from 'types/IFilter';
import AF1800HistoricReportsModal from 'components/shared/Af1800Reports/Af1800HistoricReportsModal';
import RhinoUserCacheAtom from 'store/Rhino.store';

import Loading from 'components/shared/Loading/Loading';
import Filter from 'components/shared/Filter/Filter';
import { formatDateTimeForFileName, mergeSectionIdFilters } from 'utils/Helpers';
import {
  useGetUserSettings,
  usePatchUserSettings,
} from 'apiHooks/Users.Hook';
import SubmitButton from 'components/shared/SubmitButton';
import { useGetOrganizationSections } from 'apiHooks/OrganizationSections.Hook';
import type { INullSection, IOrganizationSections } from 'types/IOrganizationSections';
import { useNavigate } from 'react-router-dom';
import AddVehiclesModal from './AddVehiclesModal';
import { FleetDetailsFilterAtom, FleetDetailsInitialFilterAtom } from './FleetDetailsFilter.store';
import FleetDetailsAgGridColDefsAtom from './FleetDetailsAgGridColDefs.store';
import { FLEET_DETAIL_FIELDS } from './FleetDetailTableColumns';
import { FleetDetailsColumnsAtom } from './FleetDetailsColumns.store';

const isFilterApplied = () => (true);

const passesTextFilter = (textFilter: IColumnFilterText, colData: string) => (
  !textFilter.filterValue.trim() || (colData || '').toUpperCase().includes(textFilter.filterValue.toUpperCase())
);

const passesBooleanFilter = (booleanFilter: IColumnFilterBoolean, colData: boolean) => (
  ((booleanFilter.options[0] === colData && booleanFilter.filterValues[0] === true)
    || (booleanFilter.options[1] === colData && booleanFilter.filterValues[1] === true)
    || (booleanFilter.filterValues[1] === booleanFilter.filterValues[0]))
);

const passesSetFilter = (setFilter: IColumnFilterSet, colData: string) => {
  const idx = setFilter.options.indexOf(colData);

  return idx === -1 || setFilter.filterValues[idx] === true;
};

const passesDateTimeFilter = (dateFilter: IColumnFilterDateTime, colData: string) => {
  const filterValue: IDateTimeFilter = { ...dateFilter.filterValue };
  const dateVal = new Date(colData);

  return ((!filterValue.endDateTime || dateVal <= filterValue.endDateTime)
    && (!filterValue.startDateTime || dateVal >= filterValue.startDateTime));
};

const passesNumberRangeFilter = (numberRangeFilter: IColumnFilterNumberRange, colData: number) => {
  if (numberRangeFilter.filterValue !== null) {
    if (numberRangeFilter.rangeType === FILTER_NUMBER_RANGE_TYPES.EQUAL_TO) {
      return colData === numberRangeFilter.filterValue;
    }

    if (numberRangeFilter.rangeType === FILTER_NUMBER_RANGE_TYPES.LESS_THAN) {
      return colData < numberRangeFilter.filterValue;
    }

    if (numberRangeFilter.rangeType === FILTER_NUMBER_RANGE_TYPES.GREATER_THAN) {
      return colData > numberRangeFilter.filterValue;
    }
  }
  return true;
};

const csvExportFileName = () => (
  `fleet-details_${formatDateTimeForFileName(new Date())}`
);

function FleetDetails() {
  const navigate = useNavigate();
  const [showFilterMenu, setShowFilterMenu] = React.useState(false);
  const [gridApi, setGridApi] = React.useState<GridApi | undefined>();
  const [columnApi, setColumnApi] = React.useState<ColumnApi>();
  const [userSettingsLoaded, setUserSettingsLoaded] = React.useState(false);
  const [rhinoUserCache, setRhinoUserCache] = useAtom(RhinoUserCacheAtom);
  const [columnDefs] = useAtom(FleetDetailsAgGridColDefsAtom);
  const [appliedFilter, setAppliedFilter] = useAtom(FleetDetailsFilterAtom);
  const [columnsState, setColumnsState] = useAtom(FleetDetailsColumnsAtom);
  const [fleetInitialFilter, setFleetInitialFilter] = useAtom(FleetDetailsInitialFilterAtom);

  const patchUserSettings = usePatchUserSettings();
  const { data: userSettings } = useGetUserSettings();

  const {
    isLoading: fleetIsLoading,
    isError,
    data: fleet,
    error,
  } = useGetFleet();

  const {
    isLoading: sectionsAreLoading,
    data: sections,
  } = useGetOrganizationSections();

  const sectionsAndNullSection: [INullSection, ...IOrganizationSections[]] | undefined = (
    sections
      ? [
        {
          sectionId: null,
          sectionName: 'No Section',
        },
        ...sections,
      ]
      : undefined
  );

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const onColumnMoved = () => {
    setColumnsState(columnApi?.getColumnState());
  };

  const saveGridView = async () => (
    patchUserSettings([
      { field: 'fleetDetailFilter', value: appliedFilter },
      { field: 'fleetDetailColumnsState', value: columnApi?.getColumnState() },
    ])
  );

  // fires when userSettings data loads for the first time (or changes)
  React.useEffect(() => {
    if (!userSettings || userSettingsLoaded) return;

    const { fleetDetailFilter, fleetDetailColumnsState } = userSettings;

    if (fleetDetailFilter
      && !Array.isArray(fleetDetailFilter)
      && Object.keys(fleetDetailFilter).length > 0
      && appliedFilter.sectionId
      && fleetDetailFilter.sectionId) {
      setAppliedFilter({
        ...fleetDetailFilter,
        sectionId: mergeSectionIdFilters(appliedFilter.sectionId, fleetDetailFilter.sectionId),
      });
    }
    if (columnsState) {
      columnApi?.applyColumnState({
        state: columnsState,
        applyOrder: true,
      });
    } else if (fleetDetailColumnsState) {
      columnApi?.applyColumnState({
        state: fleetDetailColumnsState,
        applyOrder: true,
      });
    }
    setUserSettingsLoaded(true);
  }, [
    columnApi,
    columnsState,
    setAppliedFilter,
    userSettings,
    appliedFilter,
    userSettingsLoaded,
  ]);

  React.useEffect(() => {
    const visibleCols = FLEET_DETAIL_FIELDS.filter((key) => !appliedFilter[key]?.isHidden);
    const hiddenCols = FLEET_DETAIL_FIELDS.filter((key) => appliedFilter[key]?.isHidden);
    columnApi?.setColumnsVisible(visibleCols, true);
    columnApi?.setColumnsVisible(hiddenCols, false);
    gridApi?.onFilterChanged();
  }, [appliedFilter, gridApi, columnApi]);

  React.useEffect(() => {
    if (!rhinoUserCache.FleetDetailsAgGridColDefsAtom
      || !rhinoUserCache.FleetDetailsFilterAtom
      || !rhinoUserCache.FleetDetailsInitialFilterAtom) {
      setRhinoUserCache((prev) => ({
        ...prev,
        FleetDetailsAgGridColDefsAtom,
        FleetDetailsFilterAtom,
        FleetDetailsInitialFilterAtom,
      }));
    }
  }, [rhinoUserCache]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const filterValues: boolean[] = new Array(sectionsAndNullSection?.length).fill(true);
    setAppliedFilter({
      ...appliedFilter,
      sectionId: {
        ...appliedFilter['sectionId'],
        options: sectionsAndNullSection?.map((s) => s.sectionId),
        labels: sectionsAndNullSection?.map((s) => s.sectionName),
        filterValues,
      } as IColumnFilterSet,
    });
    setFleetInitialFilter({
      ...appliedFilter,
      sectionId: {
        ...appliedFilter['sectionId'],
        options: sectionsAndNullSection?.map((s) => s.sectionId),
        labels: sectionsAndNullSection?.map((s) => s.sectionName),
        filterValues,
      } as IColumnFilterSet,
    });
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    sections,
    setAppliedFilter,
    setFleetInitialFilter,
  ]);

  const exportParams: CsvExportParams = {
    fileName: csvExportFileName(),
    processCellCallback: (params: ProcessCellForExportParams<IFleetDetail>) => {
      if (params.column.getColDef().field === 'regNumber') {
        // the following tells spreadsheets to interpret the regNumber as text.
        return `="${params.value}"`;
      }
      return params.value;
    },
  };

  const downloadCsv = () => {
    gridApi?.exportDataAsCsv();
  };

  const onRowDoubleClicked = (event: CellDoubleClickedEvent<IFleetDetail>) => {
    const { data } = event;
    if (!data) return;
    navigate(`/Vehicle/${data.regNumber}`);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const externalFilter = (node: IRowNode<IFleetDetail>) => (
    FLEET_DETAIL_FIELDS.reduce((isDisplayed, key) => {
      const fleetDetailKey = key as keyof IFleetDetail;
      const colFilter: IColumnFilter | undefined = appliedFilter[key];
      const nodeData = node.data;

      if (!isDisplayed || colFilter === undefined || colFilter.isHidden || nodeData === undefined) {
        return isDisplayed;
      }

      if (colFilter.filterType === FILTER_TYPES.TEXT) {
        return passesTextFilter(colFilter, nodeData[fleetDetailKey] as string);
      }

      if (colFilter.filterType === FILTER_TYPES.BOOLEAN) {
        const colData = key === 'inCommission' ? nodeData.decommissionDate === null : nodeData[fleetDetailKey] as boolean;

        return passesBooleanFilter(colFilter, colData);
      }

      if (colFilter.filterType === FILTER_TYPES.SET) {
        const colData = nodeData[fleetDetailKey] as string;
        return passesSetFilter(colFilter, colData);
      }

      if (colFilter.filterType === FILTER_TYPES.DATE_TIME) {
        const colData = nodeData[fleetDetailKey] as string;
        return passesDateTimeFilter(colFilter, colData);
      }

      if (colFilter.filterType === FILTER_TYPES.NUMBER_RANGE) {
        const colData = nodeData[fleetDetailKey] as number;
        return passesNumberRangeFilter(colFilter, colData);
      }

      return isDisplayed;
    }, true)
  );

  if (fleetIsLoading || sectionsAreLoading) {
    return <Loading />;
  }
  if (isError) {
    return <div>{error as string}</div>;
  }

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100">
      <h2 className="text-secondary px-3">Fleet Details</h2>
      <div className="d-flex justify-content-between bg-dark bg-gradient rounded-top p-3">
        <div className="d-flex gap-3">
          <AddVehiclesModal fleetDetails={fleet} />
          <Button onClick={downloadCsv}>Download CSV</Button>
          <AF1800HistoricReportsModal />
        </div>

        <div className="d-flex gap-3">
          <SubmitButton
            title="Save filter options and column order"
            onSubmit={saveGridView}
            canSubmit
          >
            Save View
          </SubmitButton>
          <Button
            title="Filter Fleet"
            className="align-self-center border-0 bg-transparent"
            onClick={() => setShowFilterMenu(!showFilterMenu)}
          >
            <MdFilterAlt className={(isEqual(appliedFilter, fleetInitialFilter) ? 'text-white' : 'text-primary')} />
          </Button>

        </div>
      </div>
      <div className="ag-theme-material w-100 h-100">

        <Filter filterName="Fleet Details" isOpened={showFilterMenu} />

        <AgGridReact
          onGridReady={onGridReady}
          rowData={fleet || []}
          columnDefs={columnDefs}
          maintainColumnOrder
          defaultCsvExportParams={exportParams}
          animateRows
          isExternalFilterPresent={isFilterApplied}
          doesExternalFilterPass={externalFilter}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          onColumnMoved={onColumnMoved}
          onRowDoubleClicked={onRowDoubleClicked}
        />
      </div>
    </div>
  );
}

export default FleetDetails;
