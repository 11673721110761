import React from 'react';
import { useSetAtom } from 'jotai';
import { useCookies } from 'react-cookie';
import {
  Alert,
  Form,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupText,
  Card,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MdMail, MdPassword } from 'react-icons/md';
import type { AxiosError } from 'axios';

import type {
  LoginPost,
  RhinoCookieTokens,
  RhinoMFATokens,
} from 'types/Authorization';
import TogglePasswordIcon from 'components/fields/TogglePasswordIcon';
import {
  AUTH_TOKEN_TIMEOUT_MINUTES,
  REFRESH_TOKEN_TIMEOUT_MINUTES,
  COOKIE_NAME,
} from 'utils/Constants';
import { login } from 'store/authHttp.store';
import sessionIdAtom from 'store/sessionId.store';

import logo from 'assets/img/rhino.svg';

const MFA_LOGIN_STATUSES = ['mfaNotConfigured', 'mfaRequired'];
const LOGIN_SUCCESS_STATUS = 'success';

const notAuthorizedMessage = 'No such user is authorized.';

// XXX: react-hook-form does not play well with reactstrap Input.  Wait for Reactstrap-v10

function Login() {
  const [, setCookie] = useCookies();
  const navigate = useNavigate();
  const [loginError, setLoginError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const setSessionId = useSetAtom(sessionIdAtom);
  const { register, handleSubmit, formState } = useForm<LoginPost>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      refresh: true,
      authTokenTimeoutMinutes: AUTH_TOKEN_TIMEOUT_MINUTES,
      refreshTokenTimeoutMinutes: REFRESH_TOKEN_TIMEOUT_MINUTES,
    },
  });

  const displayLoginError = (
    loginError === notAuthorizedMessage
      ? `${notAuthorizedMessage} If you are registered, please contact your administrator.`
      : loginError
  );

  const emailFormRegistration = register('email', { required: true });
  const passwordFormRegistration = register('password', { required: true });

  const onSubmitCredentials = (formData: LoginPost) => {
    login(formData.email, formData.password)
      .then((res) => {
        const { status } = res.data;
        if (MFA_LOGIN_STATUSES.includes(status)) {
          const { sessionId } = res.data as RhinoMFATokens;
          setSessionId(sessionId);
          navigate('/Mfa');
        } else if (status === LOGIN_SUCCESS_STATUS) {
          const cookieData = res.data as RhinoCookieTokens;
          setCookie(COOKIE_NAME, JSON.stringify(cookieData), { path: '/' });
        }
      })
      .catch((error: AxiosError) => {
        if (!error.response?.data) {
          setLoginError(JSON.stringify(error));
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiResponse = error.response.data as any;
        if (apiResponse.errorMessage) {
          setLoginError(apiResponse.errorMessage);
        } else if (apiResponse.errors && Array.isArray(apiResponse.errors)) {
          setLoginError(apiResponse.errors[0].message);
        } else {
          setLoginError(JSON.stringify(apiResponse));
        }
      });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 p-3 gap-3">
      <img src={logo} alt="Rhino" className="shadow-sm-lg p-3" />
      <Card className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-12 rounded">
        <CardBody>
          <Form className="bg-white rounded" onSubmit={handleSubmit(onSubmitCredentials)}>
            <InputGroup size="sm" className="mb-3">
              <InputGroupText className="mb-3 shadow-sm bg-secondary text-light">
                <MdMail className="h4 mb-0" />
              </InputGroupText>
              <FormGroup floating className="shadow-sm">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  autoComplete="username"
                  placeholder=" "
                  onChange={emailFormRegistration.onChange}
                  onBlur={emailFormRegistration.onBlur}
                  name={emailFormRegistration.name}
                  ref={emailFormRegistration.ref}
                />
                <Label htmlFor="floatingInput">Email address</Label>
                <div>
                  {formState.errors.email?.message}
                </div>
              </FormGroup>

            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <InputGroupText className="mb-3 shadow-sm bg-secondary bg-gradient text-light">
                <MdPassword className="h4 mb-0" />
              </InputGroupText>
              <FormGroup floating className="shadow-sm">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control border-end-0"
                  autoComplete="current-password"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={passwordFormRegistration.onChange}
                  onBlur={passwordFormRegistration.onBlur}
                  name={passwordFormRegistration.name}
                  ref={passwordFormRegistration.ref}
                />
                <Label htmlFor="floatingPassword">Password</Label>
              </FormGroup>
              <InputGroupText
                className="mb-3 bg-white border-start-0 rounded-end toggle-pw-visibility"
                onClick={() => setShowPassword(!showPassword)}
                title="Show/Hide password"
              >
                <TogglePasswordIcon showPassword={showPassword} />
              </InputGroupText>
              <div>
                {formState.errors.password?.message}
              </div>
            </InputGroup>
            {
              loginError && <Alert color="danger">{displayLoginError}</Alert>
            }
            <div className="d-flex justify-content-center">
              <input
                className={`btn btn-primary px-4 ${formState.isValid ? 'text-white' : 'text-dark'}`}
                type="submit"
                disabled={!formState.isValid}
                value="Log In"
              />
            </div>
          </Form>
        </CardBody>
        <CardFooter className="d-flex justify-content-between bg-secondary bg-gradient">
          <Button color="link" onClick={() => navigate('/ForgotPassword')}>Forgot Password?</Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default Login;
