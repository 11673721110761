import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  Spinner,
} from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import type { ColDef } from '@ag-grid-community/core';

import { useGetVehicleWaivers, useGetVehicleInspectionImage } from 'apiHooks/Vehicles.Hook';
import { useGetOrganizationRules } from 'apiHooks/WorkFlows.Hook';
import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import AgGridAf1800RuleCellRenderer from 'components/agGridRenderers/AgGridAf1800RuleCellRenderer';
import PdfViewer from 'components/shared/PdfViewer/PdfViewer';
import ImageLightBox from 'components/shared/ImageLightBox/ImageLightBox';
import type { IWaiveredItem, IWaiverCardFile } from 'types/IVehicleDetails';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';
import AgGridVehicleWaiverActionColumnCellRenderer from './AgGridVehicleWaiverActionColumnCellRenderer';
import AgGridWaiverImageCellRenderer from './AgGridWaiverImageCellRenderer';
import AddNewWaiveredItemModal from './AddNewIndividualWaiverModal';
import UploadVehicleWaiverCardModal from './UploadVehicleWaiverCardModal';

interface IWaiverCardFileViewer {
  waiverCardFile: IWaiverCardFile,
  regNumber: string,
}

interface IAgGridWaiver extends IWaiveredItem {
  actions?: unknown,
}

const IndividualWaiverColumnsColDef: (restrictUpdates: boolean) => ColDef<IAgGridWaiver>[] = (
  (restrictUpdates) => [
    {
      field: 'ruleId',
      cellRenderer: AgGridAf1800RuleCellRenderer,
    }, {
      field: 'explanation',
    }, {
      field: 'waiverType',
      headerName: 'Type',
      width: 100,
    }, {
      field: 'creationDate',
      valueFormatter: AgGridDateValueFormatter,
    }, {
      field: 'status',
      cellClass: 'text-capitalize',
      width: 150,
    }, {
      field: 'closingDate',
      valueFormatter: AgGridDateValueFormatter,
    }, {
      field: 'pictures',
      headerName: 'Photo(s)',
      cellRenderer: AgGridWaiverImageCellRenderer,
      width: 130,
    }, {
      field: 'actions',
      headerName: '',
      sortable: false,
      cellRenderer: AgGridVehicleWaiverActionColumnCellRenderer,
      width: 90,
      hide: restrictUpdates,
    },
  ]
);

function WaiverCardViewer({ waiverCardFile, regNumber }: IWaiverCardFileViewer) {
  const [imagePreview, setImagePreview] = React.useState('');
  const [pdfPreview, setPdfPreview] = React.useState<Uint8Array | undefined>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const getImage = useGetVehicleInspectionImage();

  const toggle = () => setModalOpen(!modalOpen);

  React.useEffect(() => {
    if (modalOpen && !imagePreview && !pdfPreview) {
      getImage(waiverCardFile.url).then((response: unknown) => {
        if (waiverCardFile.mimeType === 'application/pdf') {
          (response as Blob).arrayBuffer()
            .then((pdfArray) => pdfArray && setPdfPreview(new Uint8Array(pdfArray)))
            .catch(() => {});
        } else {
          setImagePreview(URL.createObjectURL(response as Blob));
        }
      }).catch(() => {});
    }
  }, [
    modalOpen,
    waiverCardFile,
    imagePreview,
    pdfPreview,
    getImage,
  ]);

  return (
    <div className="px-2">
      <Button onClick={toggle}>View</Button>
      <Modal className="pdf-modal" isOpen={modalOpen}>
        <RhinoModalHeader toggle={toggle}>
          {`Current Waiver Card for ${regNumber}`}
        </RhinoModalHeader>
        <ModalBody className="p-0">
          {
            (!imagePreview && !pdfPreview) ? <Spinner /> : <div />
          }
          {
            imagePreview
              ? <ImageLightBox pictures={[{ url: imagePreview, file: '' }]} preLoaded />
              : <div />
          }
          {
            pdfPreview
              ? (
                <div className="pdf-modal overflow-hidden">
                  <PdfViewer filename="" pdfData={pdfPreview} hideDownloadButton />
                </div>
              )
              : <div />
          }
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

function WaiverCardFile({ regNumber }: { regNumber: string; }) {
  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetVehicleWaivers(regNumber);

  const restrictUpdates = useRestrictUpdates();

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <span>{error as string}</span>;
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex">
        {
          data.waiverCardFile
            ? <WaiverCardViewer regNumber={regNumber} waiverCardFile={data.waiverCardFile} />
            : 'No card on file'
        }
      </div>
      {!restrictUpdates && <UploadVehicleWaiverCardModal regNumber={regNumber} />}
    </div>
  );
}

function WaiveredItems({ regNumber }: { regNumber: string; }) {
  const restrictUpdates = useRestrictUpdates();

  const [columnDefs] = useState(IndividualWaiverColumnsColDef(restrictUpdates));
  const {
    data: waiversData,
    isLoading,
    isError,
    error,
  } = useGetVehicleWaivers(regNumber);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <span>{error as string}</span>;
  }

  return (
    <div className="ag-theme-material w-100 h-100">
      <AgGridReact
        rowData={waiversData.waiveredItems}
        columnDefs={columnDefs}
        animateRows
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
      />
    </div>
  );
}

function VehicleWaivers({ regNumber }: { regNumber: string; }) {
  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetOrganizationRules();

  const restrictUpdates = useRestrictUpdates();

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <span>{error as string}</span>;
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-column p-2">
        <h4 className="m-0">Waiver Card File</h4>
        <WaiverCardFile regNumber={regNumber} />
      </div>

      <div className="d-flex justify-content-between align-items-center bg-secondary bg-gradient px-3 py-2">
        <h4 className="text-white m-0">Waivered Items</h4>
        {!restrictUpdates && <AddNewWaiveredItemModal regNumber={regNumber} rulesData={data} />}
      </div>
      <WaiveredItems regNumber={regNumber} />
    </div>
  );
}

export default VehicleWaivers;
