import React from 'react';
import {
  Button,
  FormGroup,
} from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { MdEdit } from 'react-icons/md';

import { usePatchOrganizationLeadTimes } from 'apiHooks/Organization.Hook';
import { userOrganizationName } from 'utils/Helpers';
import type { ILeadTimes, IOrganization } from 'types/IOrganization';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import type { LeadTimeField } from 'utils/constants/Organization';
import { LeadTimeFieldHeaderMap, ORG_LEAD_TIME_FIELDS } from 'utils/constants/Organization';

function EditOrganizationLeadTimesModal({ orgDetails }: { orgDetails: IOrganization; }) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [leadTimes, setLeadTimes] = React.useState<Partial<ILeadTimes>>({});
  const patchOrganizationLeadTimes = usePatchOrganizationLeadTimes();

  const leadTimeValid = (leadTimeField: LeadTimeField) => {
    const leadTime = leadTimes[leadTimeField];
    if (!leadTime) return true;
    return Number.isFinite(leadTime) && leadTime <= 180;
  };

  const isValid = () => (
    ORG_LEAD_TIME_FIELDS.some((leadTimeField) => (
      leadTimes[leadTimeField] !== orgDetails[leadTimeField]
    )) && ORG_LEAD_TIME_FIELDS.every((leadTimeField) => (
      leadTimeValid(leadTimeField)
    ))
  );

  const onSubmit = () => patchOrganizationLeadTimes(leadTimes).catch(() => {});

  return (
    <>
      <Button className="border" onClick={() => setModalOpen(true)}>
        <MdEdit />
      </Button>

      <RhinoModal
        size="sm"
        title={`Edit Lead Times for ${userOrganizationName()}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={isValid()}
        onSubmit={onSubmit}
      >
        {
          ORG_LEAD_TIME_FIELDS.map((leadTimeField) => (
            <FormGroup key={leadTimeField} floating>
              <NumericFormat
                className="form-control"
                id={`${leadTimeField}Input`}
                name={leadTimeField}
                placeholder={`${LeadTimeFieldHeaderMap[leadTimeField]} Lead Time`}
                defaultValue={orgDetails[leadTimeField]}
                decimalScale={0}
                min={0}
                allowNegative={false}
                onValueChange={({ floatValue: leadTime }) => {
                  setLeadTimes({
                    ...leadTimes,
                    ...leadTime && { [leadTimeField]: leadTime },
                  });
                }}
              />
              <label htmlFor="inputMxLeadTime">
                {`${LeadTimeFieldHeaderMap[leadTimeField]} Lead Time`}
              </label>
            </FormGroup>
          ))
        }
      </RhinoModal>
    </>
  );
}

export default EditOrganizationLeadTimesModal;
