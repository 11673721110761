import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { BatchItem, SendOptions } from '@rpldy/shared';
import { send, type SendResult } from '@rpldy/sender';
import { userOrganizationId, authToken } from 'utils/Helpers';
import type { IFleetDetail } from 'types/IFleetDetails';
import type {
  IVehicleDetails,
  IVehicleCreationPost,
  IVehiclePatch,
  IWash,
  IGetWaiversResponse,
  IWaiverPost,
  IWaiverPatch,
  IWashPatch,
  IVehicleAuxFile,
  IVehicleAuxFilePatch,
  IVehicleNote,
  IWashPost,
  IVehicle,
} from 'types/IVehicleDetails';
import type { IInspectionCommon, IPprintResult } from 'types/IVehicleInspections';
import type { IVehicleWorkflow, IRequiredMxInspectionAmendPatch } from 'types/IWorkflows';
import type {
  ILti,
  ILtiOrPmiMxPost,
  ILtiPatch,
  IPmi,
  IPmiPatch,
  IRequiredMx,
  IRequiredMxPatch,
  IRequiredMxPost,
  IScheduledMx,
  IScheduledMxPatch,
  IScheduledMxPost,
} from 'types/Mx';
import {
  authHttpDelete, authHttpGet, authHttpPatch, authHttpPost,
} from 'store/authHttp.store';

// *********************************************************************************************
// ********** GET *********
// *********************************************************************************************

const getFleetQuery = {
  queryKey: ['GetFleet'],
  queryFn: () => (
    authHttpGet<IFleetDetail[]>('/data/v2/fleet/bulk?showArchives=true')
  ),
};

const useGetFleet = () => useQuery(getFleetQuery);

const useGetVehicle = (regNumber: string) => (
  useQuery({
    queryKey: ['GetVehicle', regNumber],
    queryFn: () => (
      authHttpGet<IVehicleDetails>(`/data/v1/vehicles/${regNumber}`)
    ),
  })
);

const useGetVehicleWorkflow = (regNumber: string) => (
  useQuery({
    queryKey: ['GetVehicleWorkflow', regNumber],
    queryFn: () => (
      authHttpGet<IVehicleWorkflow>(`/data/v1/vehicles/${regNumber}/workflow`)
    ),
  })
);

const useGetVehicleMxRequired = (regNumber: string) => (
  useQuery({
    queryKey: ['GetRequiredMaintenance', regNumber],
    queryFn: () => (
      authHttpGet<IRequiredMx[]>(`/data/v1/vehicles/${regNumber}/mx/required`)
    ),
  })
);

const useGetVehicleMxScheduled = (regNumber: string) => (
  useQuery({
    queryKey: ['GetScheduledMaintenance', regNumber],
    queryFn: () => (
      authHttpGet<IScheduledMx[]>(`/data/v1/vehicles/${regNumber}/mx/scheduled`)
    ),
  })
);

const useGetVehicleMxLti = (regNumber: string) => (
  useQuery({
    queryKey: ['GetLtiMaintenance', regNumber],
    queryFn: () => (
      authHttpGet<ILti[]>(`/data/v1/vehicles/${regNumber}/mx/lti`)
    ),
  })
);

const useGetVehicleMxPmi = (regNumber: string) => (
  useQuery({
    queryKey: ['GetPmiMaintenance', regNumber],
    queryFn: () => (
      authHttpGet<IPmi[]>(`/data/v1/vehicles/${regNumber}/mx/pmi`)
    ),
  })
);

const useGetVehicleWashes = (regNumber: string) => (
  useQuery({
    queryKey: ['GetVehicleWashes', regNumber],
    queryFn: () => authHttpGet<IWash[]>(
      `/data/v1/vehicles/${regNumber}/wash`,
    ),
  })
);

const useGetVehicleWaivers = (regNumber: string) => (
  useQuery({
    queryKey: ['GetVehicleWaivers', regNumber],
    queryFn: () => authHttpGet<IGetWaiversResponse>(
      `/data/v1/vehicles/${regNumber}/waivers`, // showClosed=true
    ),
  })
);

const useGetVehicleInspectionHistory = (regNumber: string) => (
  useQuery({
    queryKey: ['GetVehicleInspectionHistory', regNumber],
    queryFn: () => (
      authHttpGet<IInspectionCommon[]>(`/data/v1/vehicles/${regNumber}/inspection/history`)
    ),
  })
);

const useGetVehicleInspection = (regNumber: string, inspectionId: number) => (
  useQuery({
    queryKey: ['GetVehicleInspection', regNumber, inspectionId],
    queryFn: () => (
      authHttpGet<IPprintResult>(`/data/v1/vehicles/${regNumber}/inspection/${inspectionId}`)
    ),
  })
);

const useGetVehicles = () => useQuery({
  queryKey: ['GetVehicles'],
  queryFn: () => (
    authHttpGet<IVehicle[]>('/data/v2/vehicles')
  ),
});

// eslint-disable-next-line unicorn/consistent-function-scoping
const useGetVehicleInspectionImage = () => (url: string) => (
  new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: authToken() || '',
        },
      }).then((response) => {
        if (response.ok) {
          resolve(response.blob());
        }
        reject(response);
      }).catch(() => {});
    } catch (error) {
      reject(error);
    }
  })
);

const useGetVehicleAuxFiles = (regNumber: string) => (
  useQuery({
    queryKey: ['GetVehicleAuxFiles', regNumber],
    queryFn: () => (
      authHttpGet<IVehicleAuxFile[]>(
        `/data/v1/vehicles/${regNumber}/files/aux`,
      )
    ),
  })
);

const useGetVehicleNotes = (regNumber: string, enabled: boolean = true) => (
  useQuery({
    queryKey: ['GetVehicleNotes', regNumber],
    queryFn: () => (
      authHttpGet<IVehicleNote[]>(
        `/data/v1/vehicles/${regNumber}/notes`,
      )
    ),
    enabled,
  })
);

const useGetRecentVehicleNote = (regNumber: string) => (
  useQuery({
    queryKey: ['GetRecentVehicleNote', regNumber],
    queryFn: () => (
      authHttpGet<[IVehicleNote] | null>(
        `/data/v1/vehicles/${regNumber}/notes/recent`,
      )
    ),
  })
);

// *********************************************************************************************
// ********** POST *********
// *********************************************************************************************

/**
 * Add New vehicles
 *
 * @param name
 * @returns
 */
const usePostNewVehicles = () => {
  const queryClient = useQueryClient();

  return (newVehicles: IVehicleCreationPost[]) => (
    Promise.all(newVehicles.map((newVehicle) => authHttpPost('/data/v1/vehicles', newVehicle)))
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetFleet'] }))
      .catch(() => queryClient.invalidateQueries({ queryKey: ['GetFleet'] }))
  );
};

const usePostNewVehicleMxPmi = (regNumber: string) => {
  const queryClient = useQueryClient();

  return ((date: Date) => {
    const pmiPost: ILtiOrPmiMxPost = {
      mxDate: date.toISOString(),
    };
    return authHttpPost(`/data/v1/vehicles/${regNumber}/mx/pmi`, pmiPost)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetPmiMaintenance', regNumber] }));
  });
};

const usePostNewVehicleMxLti = (regNumber: string) => {
  const queryClient = useQueryClient();

  return ((date: Date) => {
    const ltiPost: ILtiOrPmiMxPost = {
      mxDate: date.toISOString(),
    };
    return authHttpPost(`/data/v1/vehicles/${regNumber}/mx/lti`, ltiPost)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetLtiMaintenance', regNumber] }));
  });
};

const usePostNewVehicleMxScheduled = (regNumber: string) => {
  const queryClient = useQueryClient();

  return ((date: Date, mxType: string) => {
    const scheduledMxPost: IScheduledMxPost = {
      mxDate: date.toISOString(),
      mxType,
    };
    return authHttpPost(`/data/v1/vehicles/${regNumber}/mx/scheduled`, scheduledMxPost)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetScheduledMaintenance', regNumber] }));
  });
};

const usePostNewVehicleMxRequired = (regNumber: string) => {
  const queryClient = useQueryClient();

  return ((requiredMxPost: IRequiredMxPost) => (
    authHttpPost(`/data/v1/vehicles/${regNumber}/mx/required`, requiredMxPost)
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetRequiredMaintenance', regNumber] }))
  ));
};

const usePostNewVehicleWash = (regNumber: string) => {
  const queryClient = useQueryClient();

  return ((washPost: IWashPost) => authHttpPost(
    `/data/v1/vehicles/${regNumber}/wash`,
    washPost,
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetVehicleWashes', regNumber] }))
  );
};

const usePostNewVehicleWaiver = (regNumber: string) => {
  const queryClient = useQueryClient();

  return ((waiverPost: IWaiverPost) => authHttpPost(
    `/data/v1/vehicles/${regNumber}/waivers`,
    waiverPost,
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetVehicleWaivers', regNumber] }))
  );
};

const usePostNewVehicleWaiverCard = (regNumber: string) => {
  const queryClient = useQueryClient();

  return (items: BatchItem[], url: string | undefined, sendOptions: SendOptions) => {
    const swdUrl = `/data/v1/vehicles/${regNumber}/waivers/file`;

    const sendResponse = send(items, swdUrl, {
      ...sendOptions,
      paramName: 'card',
      headers: {
        ...sendOptions.headers,
        Authorization: authToken() || '',
      },
    });

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        queryClient.invalidateQueries({ queryKey: ['GetVehicleWaivers', regNumber] }).catch(() => {});

        return data;
      }),
    } as SendResult;
  };
};

const useNewVehicleMxRequiredWithImage = (regNumber: string) => {
  const queryClient = useQueryClient();

  return (items: BatchItem[], url: string | undefined, sendOptions: SendOptions) => {
    const swdUrl = `/data/v1/vehicles/${regNumber}/inspection/recent`;

    const { amend, ...params } = sendOptions.params || {};

    const sendResponse = send(items, swdUrl, {
      ...sendOptions,
      method: (amend ? 'PATCH' : 'POST'),
      paramName: 'pictures',
      params: {
        ...params,
        responses: JSON.stringify([{
          ...params['responses'] as object,
          pictures: items.map((batchItem) => batchItem.file.name),
        }]),
      },
      headers: {
        ...sendOptions.headers,
        Authorization: authToken() || '',
      },
    });

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        queryClient.invalidateQueries({ queryKey: ['GetRequiredMaintenance', regNumber] }).catch(() => {});

        return data;
      }),
    } as SendResult;
  };
};

const usePostVehicleAuxFile = (regNumber: string) => {
  const queryClient = useQueryClient();

  return (
    items: BatchItem[],
    description: string,
    url: string | undefined,
    sendOptions: SendOptions,
  ) => {
    const swdUrl = `/data/v1/vehicles/${regNumber}/files/aux`;

    const sendFileResponse = send(items, swdUrl, {
      ...sendOptions,
      paramName: 'file',
      headers: {
        ...sendOptions.headers,
        Authorization: authToken() || '',
      },
      params: { description },
    });

    return {
      ...sendFileResponse,
      request: sendFileResponse.request.then((data) => {
        queryClient.invalidateQueries({ queryKey: ['GetVehicleAuxFiles', regNumber] })
          .catch(() => {});

        return data;
      }),
    } as SendResult;
  };
};

const usePostVehicleNote = (regNumber: string) => {
  const queryClient = useQueryClient();

  return (note: string) => (
    authHttpPost(
      `/data/v1/vehicles/${regNumber}/notes`,
      { note },
    )
      .then(() => Promise.all([
        queryClient.invalidateQueries({ queryKey: ['GetVehicleNotes', regNumber] }),
        queryClient.invalidateQueries({ queryKey: ['GetRecentVehicleNote', regNumber] }),
      ]))
  );
};

// *********************************************************************************************
// ********** PATCH *********
// *********************************************************************************************

/**
 * Update vehicle
 *
 * @param
 * @returns
 */
const usePatchVehicle = () => {
  const queryClient = useQueryClient();

  return (regNumber: string, patchData: IVehiclePatch) => (
    authHttpPatch(`/data/v2/vehicles/${regNumber}`, patchData)
      .then(() => Promise.all([
        queryClient.invalidateQueries({ queryKey: ['GetFleet'] }),
        patchData.orgId
          ? Promise.resolve()
          : queryClient.invalidateQueries({ queryKey: ['GetVehicle', patchData.regNumber || regNumber] }),
      ]))
      .catch(() => Promise.all([
        queryClient.invalidateQueries({ queryKey: ['GetFleet'] }),
        queryClient.invalidateQueries({ queryKey: ['GetVehicle', regNumber] }),
      ]))
  );
};

const usePatchPmi = (regNumber: string, pmiId: number) => {
  const queryClient = useQueryClient();

  return (pmiPatch: IPmiPatch) => (
    authHttpPatch(
      `/data/v1/organizations/${userOrganizationId()}/mx/pmi/${pmiId}`,
      pmiPatch,
    )
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetPmiMaintenance', regNumber] }))
  );
};

const usePatchLti = (regNumber: string, ltiId: number) => {
  const queryClient = useQueryClient();

  return (ltiPatch: ILtiPatch) => (
    authHttpPatch(
      `/data/v1/organizations/${userOrganizationId()}/mx/lti/${ltiId}`,
      ltiPatch,
    )
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetLtiMaintenance', regNumber] }))
  );
};

const usePatchWash = (regNumber: string, washId: number) => {
  const queryClient = useQueryClient();

  return (washPatch: IWashPatch) => (
    authHttpPatch(
      `/data/v1/vehicles/${regNumber}/wash/${washId}`,
      washPatch,
    )
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetVehicleWashes', regNumber] }))
  );
};

const usePatchIndividualWaiver = (regNumber: string, waiverId: number) => {
  const queryClient = useQueryClient();

  return ((patchData: IWaiverPatch) => authHttpPatch(
    `/data/v1/vehicles/${regNumber}/waivers/${waiverId}`,
    patchData,
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetVehicleWaivers', regNumber] }))
  );
};

const usePatchIndividualWaiverImageAnnotation = (regNumber: string, waiverId: number) => {
  const queryClient = useQueryClient();

  return ((pictureNumber: string | number, annotation: string) => authHttpPatch(
    `/data/v1/vehicles/${regNumber}/waivers/${waiverId}/pictures/${pictureNumber}`,
    { annotation },
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetVehicleWaivers', regNumber] }))
  );
};

const usePatchScheduledMx = (regNumber: string, scheduledMxId: number) => {
  const queryClient = useQueryClient();

  return (scheduledMxPatch: IScheduledMxPatch) => (
    authHttpPatch(
      `/data/v1/organizations/${userOrganizationId()}/mx/scheduled/${scheduledMxId}`,
      scheduledMxPatch,
    )
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetScheduledMaintenance', regNumber] }))
  );
};

const usePatchRequiredMx = (regNumber: string, requiredMxId: number) => {
  const queryClient = useQueryClient();

  return (requiredMxPatch: IRequiredMxPatch) => (
    authHttpPatch(
      `/data/v1/vehicles/${regNumber}/mx/required/${requiredMxId}`,
      requiredMxPatch,
    )
      .then(() => Promise.all([
        queryClient.invalidateQueries({ queryKey: ['GetRequiredMaintenance', regNumber] }),
        queryClient.invalidateQueries({ queryKey: ['GetVehicle', regNumber] }),
      ]))
  );
};

const useNewVehicleMxRequiredWithoutImage = (regNumber: string) => {
  const queryClient = useQueryClient();

  return (requiredMxPatch: IRequiredMxInspectionAmendPatch) => {
    const formData = new FormData();

    formData.append('version', requiredMxPatch.version);
    formData.append('workflowId', String(requiredMxPatch.workflowId));
    formData.append('responses', requiredMxPatch.responses as string);
    if (requiredMxPatch.miles) {
      formData.append('miles', String(requiredMxPatch.miles));
    }

    if (requiredMxPatch.hours) {
      formData.append('hours', String(requiredMxPatch.hours));
    }

    return authHttpPatch(`/data/v2/vehicles/${regNumber}/inspection/recent`, formData)
      .then(() => Promise.all([
        queryClient.invalidateQueries({ queryKey: ['GetRequiredMaintenance', regNumber] }),
        queryClient.invalidateQueries({ queryKey: ['GetVehicle', regNumber] }),
      ]));
  };
};

const usePatchNewWaiverImage = (regNumber: string, waiverId: string | number) => {
  const queryClient = useQueryClient();

  return (items: BatchItem[], url: string | undefined, sendOptions: SendOptions) => {
    const swdUrl = `/data/v1/vehicles/${regNumber}/waivers/${waiverId}`;

    const sendResponse = send(items, swdUrl, {
      ...sendOptions,
      method: 'PATCH',
      paramName: 'pictures',
      headers: {
        ...sendOptions.headers,
        Authorization: authToken() || '',
      },
    });

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        queryClient.invalidateQueries({ queryKey: ['GetVehicleWaivers', regNumber] }).catch(() => {});

        return data;
      }),
    } as SendResult;
  };
};

const usePatchVehicleAuxFile = (regNumber: string, filename: string) => {
  const queryClient = useQueryClient();

  return (auxFilePatch: IVehicleAuxFilePatch) => (
    authHttpPatch(
      `/data/v1/vehicles/${regNumber}/files/aux/${filename}`,
      auxFilePatch,
    )
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetVehicleAuxFiles', regNumber] }))
  );
};

const usePatchNewRequiredMxImage = (
  regNumber: string | undefined,
  requiredMxId: number | undefined,
) => {
  const queryClient = useQueryClient();

  return (items: BatchItem[], url: string | undefined, sendOptions: SendOptions) => {
    const swdUrl = `/data/v1/vehicles/${regNumber}/mx/required/${requiredMxId}`;

    const sendResponse = send(items, swdUrl, {
      ...sendOptions,
      method: 'PATCH',
      paramName: 'pictures',
      headers: {
        ...sendOptions.headers,
        Authorization: authToken() || '',
      },
    });

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        queryClient.invalidateQueries({ queryKey: ['GetRequiredMaintenance', regNumber] }).catch(() => {});
        queryClient.invalidateQueries({ queryKey: ['GetVehicle', regNumber] }).catch(() => {});

        return data;
      }),
    } as SendResult;
  };
};

const useRecommissionVehicle = (regNumber: string) => {
  const queryClient = useQueryClient();

  const recommissionPatch: IVehiclePatch = {
    decommissioned: false,
  } as const;

  return () => authHttpPatch(
    `/data/v1/vehicles/${regNumber}`,
    recommissionPatch,
  )
    .then(() => Promise.all([
      queryClient.invalidateQueries({ queryKey: ['GetVehicle', regNumber] }),
      queryClient.invalidateQueries({ queryKey: ['GetFleet'] }),
    ]));
};

// *********************************************************************************************
// ********** Delete *********
// *********************************************************************************************

const useDecommissionVehicle = (regNumber: string) => {
  const queryClient = useQueryClient();

  return () => authHttpDelete(
    `/data/v1/vehicles/${regNumber}`,
  )
    .then(() => Promise.all([
      queryClient.invalidateQueries({ queryKey: ['GetVehicle', regNumber] }),
      queryClient.invalidateQueries({ queryKey: ['GetFleet'] }),
    ]));
};

const useDeleteWaiverImage = (regNumber: string, waiverId: number) => {
  const queryClient = useQueryClient();

  return ((pictureNumber: string | number) => authHttpDelete(
    `/data/v1/vehicles/${regNumber}/waivers/${waiverId}/pictures/${pictureNumber}`,
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetVehicleWaivers', regNumber] }))
  );
};

const useDeleteRequiredMxImage = (
  regNumber: string | undefined,
  requiredMxId: number | undefined,
) => {
  const queryClient = useQueryClient();

  return ((pictureNumber: string | number) => authHttpDelete(
    `/data/v1/vehicles/${regNumber}/mx/required/${requiredMxId}/pictures/${pictureNumber}`,
  )
    .then(() => Promise.all([
      queryClient.invalidateQueries({ queryKey: ['GetRequiredMaintenance', regNumber] }),
      queryClient.invalidateQueries({ queryKey: ['GetVehicle', regNumber] }),
    ])));
};

const useDeleteVehicleAuxFile = (regNumber: string) => {
  const queryClient = useQueryClient();

  return ((filename: string) => (
    authHttpDelete(
      `/data/v1/vehicles/${regNumber}/files/aux/${filename}`,
    )
      .then(() => queryClient.invalidateQueries({ queryKey: ['GetVehicleAuxFiles', regNumber] }))
  ));
};

export {
  useGetFleet,
  useGetVehicle,
  useDecommissionVehicle,
  useRecommissionVehicle,

  useGetVehicleMxRequired,
  useGetVehicleMxScheduled,
  useGetVehicleMxLti,
  useGetVehicleMxPmi,
  usePostNewVehicleMxRequired,
  usePostNewVehicleMxScheduled,
  usePostNewVehicleMxPmi,
  usePostNewVehicleMxLti,
  useNewVehicleMxRequiredWithImage,

  useGetVehicleWashes,
  usePostNewVehicleWash,
  usePatchWash,

  useGetVehicleWaivers,
  usePostNewVehicleWaiver,
  usePostNewVehicleWaiverCard,
  usePatchNewWaiverImage,
  usePatchIndividualWaiver,
  usePatchIndividualWaiverImageAnnotation,

  useDeleteWaiverImage,
  useDeleteRequiredMxImage,

  usePatchPmi,
  usePatchLti,
  usePatchScheduledMx,
  usePatchRequiredMx,
  usePatchNewRequiredMxImage,
  useNewVehicleMxRequiredWithoutImage,

  useGetVehicles,
  useGetVehicleInspectionHistory,
  useGetVehicleInspection,
  usePostNewVehicles,
  usePatchVehicle,
  useGetVehicleInspectionImage,
  useGetVehicleWorkflow,
  getFleetQuery,

  useGetVehicleNotes,
  useGetRecentVehicleNote,
  usePostVehicleNote,

  useGetVehicleAuxFiles,
  usePostVehicleAuxFile,
  usePatchVehicleAuxFile,
  useDeleteVehicleAuxFile,
};
