import type { ISelectObject } from 'types/ISelectObject';

const RANKS: string[] = [
  'E-1', 'E-2', 'E-3', 'E-4', 'E-5', 'E-6', 'E-7', 'E-8', 'E-9',
  'O-1', 'O-2', 'O-3', 'O-4', 'O-5', 'O-6', 'O-7', 'O-8', 'O-9', 'O-10',
  'DR-1', 'DR-2', 'DR-3', 'DR-4',
  'CTR', 'CIV',
];

const RANKS_SELECT_OPTIONS: ISelectObject[] = RANKS.map((rank) => ({
  value: rank,
  label: rank,
}));

export {
  RANKS,
  RANKS_SELECT_OPTIONS,
};
