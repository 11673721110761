import React, { useState } from 'react';
import { decode } from 'html-entities';
import { FormGroup, Label, Spinner } from 'reactstrap';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';
import type { IWorkflow, IWorkflowRule } from 'types/IWorkflows';
import type { ITypeAheadReactHookForm } from 'types/ITypeAheadReactHookForm';
import customFilterBy from 'utils/helpers/customFilterBy.Helper';
import { useGetVehicleWorkflow } from 'apiHooks/Vehicles.Hook';

interface IVehicleWorkflowRulesSelect extends ITypeAheadReactHookForm {
  regNumber: string
}

const workflowRuleLabelKey = (rule: IWorkflowRule) => (
  decode(rule.title) || rule.af1800Id || String(rule.ruleId)
);

function VehicleWorkflowAf1800RuleSelect({
  regNumber,
  className,
  id,
  onValueChange,
  onBlur,
  name,
  formRef,
  defaultValue,
  value,
  isInvalid,
} : IVehicleWorkflowRulesSelect) {
  const [touched, setTouched] = useState(false);
  const [af1800Rules, setAf1800Rules] = useState<IWorkflowRule[]>([]);

  const {
    data: workflowData,
    isLoading,
    isError,
    error,
  } = useGetVehicleWorkflow(regNumber);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  if (af1800Rules.length === 0) {
    setAf1800Rules(workflowData.rules.filter((x: IWorkflowRule) => (
      x.isBoolean
      && (x.af1800Id || Number.isFinite(Number.parseInt(String(x.ruleId).replace('AF1800-', ''), 10)))
    )));
  }

  return (
    <Typeahead
      id={id}
      className={className || ''}
      options={af1800Rules}
      filterBy={customFilterBy<IWorkflowRule>('title')}
      labelKey={(workflow) => workflowRuleLabelKey(workflow as IWorkflowRule)}
      placeholder=" "
      highlightOnlyResult
      onChange={(selected) => {
        if (!onValueChange) {
          return;
        }
        const workflowSelected = selected as IWorkflow[];
        if (workflowSelected.length === 0) {
          onValueChange(undefined);
        } else {
          onValueChange(workflowSelected[0]);
        }
      }}
      inputProps={{ name, value }}
      defaultSelected={af1800Rules.filter((x) => x.ruleId === defaultValue)}
      ref={formRef}
      size="lg"
      renderInput={({ inputRef, referenceElementRef, ...inputProperties }, state) => (
        <Hint>
          <FormGroup floating>
            <input
              {...inputProperties /* eslint-disable-line react/jsx-props-no-spreading */}
              id={id}
              className={`form-control ${className || ''} ${(isInvalid && touched) ? 'is-invalid' : ''}`}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
              onBlur={(event) => {
                state.hideMenu();
                setTouched(true);

                if (onBlur) {
                  onBlur(event);
                }
                return event;
              }}
            />
            <Label for={id}>AF1800 Rule</Label>
          </FormGroup>
        </Hint>
      )}
    />
  );
}

export default VehicleWorkflowAf1800RuleSelect;
