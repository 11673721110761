import React from 'react';
import { useAtom } from 'jotai';
import { useCookies } from 'react-cookie';
import {
  Alert,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Card,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { MdSecurity } from 'react-icons/md';
import { PatternFormat } from 'react-number-format';
import axios, { type AxiosError } from 'axios';

import {
  COOKIE_NAME,
} from 'utils/Constants';
import { submitMfa } from 'store/authHttp.store';
import logo from 'assets/img/rhino.svg';
import sessionIdAtom from 'store/sessionId.store';

const LOGIN_SUCCESS_STATUS = 'success';

function Mfa() {
  const [, setCookie] = useCookies();
  const [sessionId, setSessionId] = useAtom(sessionIdAtom);
  const navigate = useNavigate();
  const [loginError, setLoginError] = React.useState('');
  const [trustDevice, setTrustDevice] = React.useState(true);
  const [token, setToken] = React.useState<string>();
  const [emailSubmitted, setEmailSubmitted] = React.useState(false);

  const onSubmitMfa = (event: React.FormEvent) => {
    event.preventDefault();

    if (!token) {
      return;
    }

    submitMfa(sessionId, token, trustDevice)
      .then((res) => {
        if (res.data.status === LOGIN_SUCCESS_STATUS) {
          setCookie(COOKIE_NAME, JSON.stringify(res.data), { path: '/' });
          setSessionId('');
          navigate('/', { replace: true });
        }
      })
      .catch((error: AxiosError) => {
        if (!error.response?.data) {
          setLoginError(JSON.stringify(error));
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiResponse = error.response.data as any;
        if (apiResponse.errorMessage) {
          setLoginError(apiResponse.errorMessage);
        } else if (apiResponse.errors && Array.isArray(apiResponse.errors)) {
          setLoginError(apiResponse.errors[0].message);
        } else {
          setLoginError(JSON.stringify(apiResponse));
        }
      });
  };

  const resendEmail = () => {
    axios.post('auth/v1/configureMfa', { sessionId })
      .then(() => setEmailSubmitted(true))
      .catch((error: AxiosError) => {
        if (!error.response?.data) {
          setLoginError(JSON.stringify(error));
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiResponse = error.response.data as any;
        if (apiResponse.errorMessage) {
          setLoginError(apiResponse.errorMessage);
        } else if (apiResponse.errors && Array.isArray(apiResponse.errors)) {
          setLoginError(apiResponse.errors[0].message);
        } else {
          setLoginError(JSON.stringify(apiResponse));
        }
      });
  };

  if (!sessionId) {
    navigate('/', { replace: true });
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 p-3 gap-3">
      <img src={logo} alt="Rhino" className="shadow-sm-lg p-3" />
      <Card className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-12 rounded">
        <CardBody>
          <Form className="bg-white rounded" autoComplete="off" onSubmit={onSubmitMfa}>
            <h6 className="text-muted">Please Enter Your One-Time Rhino Code/Token:</h6>
            <InputGroup size="sm">
              <InputGroupText className="mb-3 shadow-sm bg-secondary text-light">
                <MdSecurity className="h4 mb-0" />
              </InputGroupText>
              <FormGroup floating className="shadow-sm">
                <PatternFormat
                  min={1}
                  className="form-control"
                  placeholder=" "
                  onValueChange={({ value }) => setToken(value)}
                  format="# # # # # #"
                  autoComplete="off"
                  allowEmptyFormatting
                  id="floatingMfaInput"
                  mask="_"
                />
                <Label htmlFor="floatingMfaInput">Rhino MFA Token</Label>
              </FormGroup>

            </InputGroup>
            <div className="d-flex px-4">
              <FormGroup
                check
                inline
              >
                <Label check className="user-select-none">
                  <Input
                    type="checkbox"
                    className="shadow-sm"
                    defaultChecked={trustDevice}
                    onChange={(e) => setTrustDevice(e.currentTarget.checked)}
                  />
                  Trust this device for one week.
                </Label>
              </FormGroup>
            </div>
            {
              loginError && <Alert color="danger">{loginError}</Alert>
            }
            {
              emailSubmitted
              && (
                <Alert className="text-center" color="success">
                  Reset request submitted.  Please check your email for next steps.
                </Alert>
              )
            }
            <div className="d-flex justify-content-center mt-3">
              <input
                className={`btn btn-primary px-4 ${token?.length === 6 ? 'text-white' : 'text-dark'}`}
                type="button"
                onClick={onSubmitMfa}
                disabled={token?.length !== 6}
                value="Complete Log In"
              />
            </div>
          </Form>
        </CardBody>
        <CardFooter className="d-flex justify-content-between bg-secondary bg-gradient">
          <Button color="link" onClick={() => navigate('/')}>Back to Login</Button>
          <Button color="link" onClick={() => resendEmail()}>Resend MFA instructions to my Email</Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default Mfa;
