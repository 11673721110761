import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import type {
  TokenParams,
  RefreshPost,
  LoginPost,
  RhinoMFATokens,
  RhinoCookieTokens,
} from 'types/Authorization';
import { authToken as getBearerToken } from 'utils/Helpers';
import { refreshToken as getRefreshToken } from 'utils/helpers/NonReactCookie.Helper';
import {
  AUTH_TOKEN_TIMEOUT_MINUTES,
  REFRESH_TOKEN_TIMEOUT_MINUTES,
  COOKIE_NAME,
} from 'utils/Constants';

const DEFAULT_TOKEN_PARAMS: TokenParams = {
  refresh: true,
  authTokenTimeoutMinutes: AUTH_TOKEN_TIMEOUT_MINUTES,
  refreshTokenTimeoutMinutes: REFRESH_TOKEN_TIMEOUT_MINUTES,
};

const cookies = new Cookies();

const authHttp = axios.create();

authHttp.interceptors.request.use(
  (config) => {
    const token = getBearerToken();
    config.headers.set('Authorization', token);
    return config;
  },
  (error) => Promise.reject(error),
);

const refreshBearerToken = () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) return Promise.resolve();

  const refreshPost: RefreshPost = {
    token: refreshToken,
    ...DEFAULT_TOKEN_PARAMS,
  };
  return (
    axios.post<RhinoCookieTokens>(
      '/auth/v1/refresh',
      refreshPost,
    ).then((response) => {
      cookies.set(COOKIE_NAME, JSON.stringify(response.data), { path: '/' });
    }).catch(() => {
      cookies.remove(COOKIE_NAME);
      window.location.href = '/';
    })
  );
};

createAuthRefreshInterceptor(
  authHttp,
  refreshBearerToken,
  {
    statusCodes: [401],
    pauseInstanceWhileRefreshing: true,
  },
);

/**
 *
 * @param email
 * @param password
 * @returns
 */
const login = (email: string, password: string) => {
  const loginPost: LoginPost = {
    email,
    password,
    ...DEFAULT_TOKEN_PARAMS,
  };

  return (
    axios.post<RhinoCookieTokens | RhinoMFATokens>(
      '/auth/v1/login?formatAsJson=true',
      loginPost,
    )
  );
};

/**
 *
 * @param sessionId
 * @param token
 * @returns
 */
const submitMfa = (sessionId: string, token: string, trustDevice: boolean) => (
  axios.post<RhinoCookieTokens>(
    '/auth/v1/submitMfa',
    { sessionId, token, trustDevice },
  )
);

/**
 * Sugar syntax to resolve axios response.
 *
 * @param res
 * @returns
 */
const resolveData = <T>(res: AxiosResponse<T>) => res.data;

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Sugar syntax to resolve axios error.
 *
 * @param error
 * @returns
 */
const resolveError = (error: any) => {
  const { response } = error || {};
  if (response) {
    const { status, url } = response;
    if (status === 403 || (status === 404 && url.includes('refresh'))) {
      cookies.remove(COOKIE_NAME);
      window.location.href = '/';
    } else if (status === 401) {
      return refreshBearerToken();
    }
  }

  return error;
};

/* eslint-disable unicorn/no-useless-promise-resolve-reject */
const authHttpGet = <T = any>(url: string): Promise<T> => (
  authHttp.get<T>(url)
    .then(resolveData<T>)
    .catch((error) => Promise.reject(resolveError(error)))
);
const authHttpPost = <T = void>(url: string, data?: any): Promise<T> => (
  authHttp.post<T>(url, data)
    .then(resolveData<T>)
    .catch((error) => Promise.reject(resolveError(error)))
);
const authHttpPatch = <T = void>(url: string, data?: any): Promise<T> => (
  authHttp.patch<T>(url, data)
    .then(resolveData<T>)
    .catch((error) => Promise.reject(resolveError(error)))
);
const authHttpDelete = <T = void>(url: string): Promise<T> => (
  authHttp.delete<T>(url)
    .then(resolveData<T>)
    .catch((error) => Promise.reject(resolveError(error)))
);
/* eslint-enable unicorn/no-useless-promise-resolve-reject */
/* eslint-enable @typescript-eslint/no-explicit-any */

export {
  authHttp,
  authHttpGet,
  authHttpPost,
  authHttpPatch,
  authHttpDelete,
  login,
  submitMfa,
};
