import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import type { ColDef, ValueFormatterParams } from '@ag-grid-community/core';

import { useGetOrganizationRules } from 'apiHooks/WorkFlows.Hook';
import type { RuleType, RuleFrequency } from 'types/common';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import AgGridBooleanIconCellRenderer from 'components/agGridRenderers/AgGridBooleanIconCellRenderer';
import AgGridHtmlDecodeValueFormatter from 'components/agGridRenderers/AgGridHtmlDecodeValueFormatter';
import {
  WORKFLOW_RULE_LABEL,
  WORKFLOW_RULE_FREQUENCY_LABEL,
} from 'utils/Constants';

import { ruleIsCustom } from 'utils/Helpers';
import style from './PreSavedRulesModal.module.css';

const columnDefs: ColDef[] = [
  {
    field: 'title',
    cellRenderer: AgGridHtmlDecodeValueFormatter,
  }, {
    field: 'type',
    valueFormatter: ({ value }: ValueFormatterParams) => (
      WORKFLOW_RULE_LABEL[value as RuleType] || value
    ),
  }, {
    field: 'description',
    cellRenderer: AgGridHtmlDecodeValueFormatter,
  }, {
    field: 'frequency',
    valueFormatter: ({ value }: ValueFormatterParams) => (
      WORKFLOW_RULE_FREQUENCY_LABEL[value as RuleFrequency] || value
    ),
  }, {
    field: 'fields.fixable',
    headerName: 'Fixable',
    cellRenderer: AgGridBooleanIconCellRenderer,
  }, {
    field: 'display',
  },
];

function PreSavedRulesModal() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { isLoading, data: rulesData } = useGetOrganizationRules();

  const toggle = () => setModalOpen(!modalOpen);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="d-flex justify-content-center pt-2">
        <Button
          type="button"
          onClick={toggle}
        >
          View Pre-Saved Rules
        </Button>
      </div>
      <Modal
        isOpen={modalOpen}
        toggle={toggle}
        className="image-lightbox-modal"
      >

        <RhinoModalHeader toggle={toggle}>
          Pre-saved Rules
        </RhinoModalHeader>
        <ModalBody>
          <div className={`ag-theme-material w-100 ${style['pre-saved-table'] || ''}`}>
            <AgGridReact
              rowData={rulesData?.filter(ruleIsCustom) || []}
              columnDefs={columnDefs}
              animateRows
              defaultColDef={{
                sortable: true,
                resizable: true,
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PreSavedRulesModal;
