import React from 'react';
import {
  Route,
  Navigate,
} from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';

import Login from 'views/Login/Login';
import ForgotPassword from 'views/Login/ForgotPassword';
import PasswordReset from 'views/Login/PasswordReset';
import Mfa from 'views/Login/Mfa';

import styles from './LoginLayout.module.css';

function LoginLayout() {
  return (
    <div className={`vh-100 vw-100 ${styles['login-background'] || ''}`}>
      <SlideRoutes>
        <Route
          path="/ForgotPassword"
          key="ForgotPassword"
          element={<ForgotPassword />}
        />
        <Route
          path="/"
          key="Login"
          element={<Login />}
        />
        <Route
          path="/PasswordReset"
          key="PasswordReset"
          element={<PasswordReset />}
        />
        <Route
          path="/Mfa"
          key="Mfa"
          element={<Mfa />}
        />

        <Route path="*" element={<Navigate replace to="/" />} />
      </SlideRoutes>
    </div>
  );
}

export default LoginLayout;
