import { useGetVehicleWashes } from 'apiHooks/Vehicles.Hook';
import React, { useEffect } from 'react';
import { Input, Label, Spinner } from 'reactstrap';
import type { ColDef, ColumnApi, GridReadyEvent } from '@ag-grid-community/core';
import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import type { IWash } from 'types/IVehicleDetails';
import { AgGridReact } from '@ag-grid-community/react';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';
import ScheduleVehicleWashModal from './ScheduleVehicleWashModal';
import AgGridEditWashCellRenderer from './AgGridEditWashCellRenderer';

interface IAgGridWash extends IWash {
  edit: unknown,
}

const WASHES_COLUMNS_COL_DEFS: (restrictUpdates: boolean) => ColDef<IAgGridWash>[] = (
  (restrictUpdates) => [
    {
      field: 'completionDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Completed On',
      initialHide: true,
      width: 250,
    }, {
      field: 'washDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Scheduled For',
      width: 250,
    }, {
      field: 'lastModificationDate',
      valueFormatter: AgGridDateValueFormatter,
      headerName: 'Last Updated',
      width: 250,
    }, {
      field: 'creatorName',
      headerName: 'Scheduled By',
      width: 250,
    }, {
      field: 'lastModifierName',
      headerName: 'Updated By',
      width: 250,
    }, {
      field: 'edit',
      headerName: '',
      cellRenderer: AgGridEditWashCellRenderer,
      width: 100,
      hide: restrictUpdates,
    },
  ]
);

export default function VehicleWashes({ regNumber }: { regNumber: string; }) {
  const restrictUpdates = useRestrictUpdates();

  const [columnDefs] = React.useState(WASHES_COLUMNS_COL_DEFS(restrictUpdates));
  const [columnApi, setColumnApi] = React.useState<ColumnApi>();
  const [showHistoric, setShowHistoric] = React.useState(false);
  const [currentRowData, setCurrentRowData] = React.useState<IWash[]>();

  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetVehicleWashes(regNumber);

  useEffect(() => {
    setCurrentRowData(data?.filter((x) => !x.completed));
  }, [data]);

  const onGridReady = (params: GridReadyEvent) => {
    setColumnApi(params.columnApi);
  };

  const toggleShowHistoric = () => {
    columnApi?.setColumnsVisible(['completionDate'], !showHistoric);
    setShowHistoric((b) => !b);
  };

  if (isLoading || !data) return <Spinner />;
  if (isError) return error as string;

  return (
    <div className="d-flex flex-column h-100">
      <div className="w-100 d-flex p-2 align-items-center justify-content-between border-bottom">
        <Label>
          <span className="px-2">Show Completed Washes:</span>
          <Input
            defaultChecked={showHistoric}
            onClick={toggleShowHistoric}
            type="checkbox"
          />
        </Label>
        {!restrictUpdates && <ScheduleVehicleWashModal regNumber={regNumber} />}
      </div>

      <div className="ag-theme-material w-100 h-100">
        <AgGridReact
          rowData={(showHistoric ? data : (currentRowData || []))}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          animateRows
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
        />
      </div>
    </div>
  );
}
