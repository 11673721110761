import { usePatchOrganizationContactInfo } from 'apiHooks/Organization.Hook';
import { useGetUserProfiles } from 'apiHooks/Users.Hook';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import React, { useState } from 'react';
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap';
import type { IContactPatch, IOrganization } from 'types/IOrganization';
import { formatNameAndRank, userOrganizationName } from 'utils/Helpers';
import { validatePhoneInput, validatePhoneSubmission } from 'utils/helpers/validatePhoneInput.Helper';

interface Props {
  org: IOrganization,
}

export default function EditContactInfoModal(props: Props) {
  const { org } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [primaryContactUserId, setPrimaryContactUserId] = useState(
    org.contactInfo[0]?.primaryContactUserId ?? null,
  );
  const [primaryContactPhoneNumber, setPrimaryContactPhoneNumber] = useState(
    org.contactInfo[0]?.primaryContactPhoneNumber ?? null,
  );
  const [basePhoneNumber, setBasePhoneNumber] = useState(
    org.contactInfo[0]?.basePhoneNumber ?? null,
  );

  const patchContactInfo = usePatchOrganizationContactInfo();

  const {
    data: profiles,
    isLoading,
    isError,
    error,
  } = useGetUserProfiles(modalOpen);

  const vcoProfiles = profiles?.filter((p) => p.role === 'vco')
    .sort((a, b) => (formatNameAndRank(a) < formatNameAndRank(b) ? -1 : 1));

  const primaryContactProfile = vcoProfiles?.find((p) => p.id === primaryContactUserId);

  const resetState = () => {
    setErrorMessage('');
    setPrimaryContactUserId(org.contactInfo[0]?.primaryContactUserId ?? null);
    setPrimaryContactPhoneNumber(org.contactInfo[0]?.primaryContactPhoneNumber ?? null);
    setBasePhoneNumber(org.contactInfo[0]?.basePhoneNumber ?? null);
  };

  const handleSubmit = async () => {
    if (primaryContactPhoneNumber && validatePhoneSubmission(primaryContactPhoneNumber) === null) {
      setErrorMessage(`Primary Contact Phone Number ${primaryContactPhoneNumber} invalid.`);
      throw error;
    }
    if (basePhoneNumber && validatePhoneSubmission(basePhoneNumber) === null) {
      setErrorMessage(`Base Phone Number ${basePhoneNumber} invalid.`);
      throw error;
    }

    setErrorMessage('');

    const contactPatch: IContactPatch = {
      ...primaryContactUserId !== org.contactInfo[0]?.primaryContactUserId && {
        primaryContactUserId,
      },
      ...primaryContactPhoneNumber !== org.contactInfo[0]?.primaryContactPhoneNumber && {
        primaryContactPhoneNumber,
      },
      ...basePhoneNumber !== org.contactInfo[0]?.basePhoneNumber && {
        basePhoneNumber,
      },
    };

    await patchContactInfo(contactPatch);
    // .catch(() => setErrorMessage('Invalid submission'));
  };

  if (isError) return error as string;

  return (
    <>
      <Button
        className="border"
        onClick={() => setModalOpen(true)}
      >
        Contact Info
      </Button>

      <RhinoModal
        title={`Edit Contact Info for ${userOrganizationName()}`}
        size="sm"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSubmit={handleSubmit}
        onClosed={resetState}
        bodyClassName="d-grid gap-3"
      >
        {
          isLoading
            ? (
              <Spinner />
            ) : (
              <>
                <div>
                  <div className="mx-2 mb-1">
                    Primary Contact
                  </div>
                  <UncontrolledDropdown>
                    <DropdownToggle caret className="form-control bg-white text-dark border">
                      <span>{formatNameAndRank(primaryContactProfile)}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      {vcoProfiles?.map((profile) => (
                        <DropdownItem
                          key={profile.id}
                          onClick={() => setPrimaryContactUserId(profile.id)}
                        >
                          {formatNameAndRank(profile)}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <div>
                  <div className="mx-2 mb-1">Primary Contact Phone Number</div>
                  <Input
                    value={primaryContactPhoneNumber ?? undefined}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) setPrimaryContactPhoneNumber(null);
                      const validatedPhone = validatePhoneInput(value);
                      if (validatedPhone !== null) setPrimaryContactPhoneNumber(validatedPhone);
                    }}
                  />
                </div>

                <div>
                  Base Phone Number
                  <Input
                    value={basePhoneNumber ?? undefined}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) setBasePhoneNumber(null);
                      const validatedPhone = validatePhoneInput(value);
                      if (validatedPhone !== null) setBasePhoneNumber(validatedPhone);
                    }}
                  />
                </div>

                <Collapse isOpen={!!errorMessage} className="mt-3 text-danger">
                  {errorMessage}
                </Collapse>
              </>
            )
        }

      </RhinoModal>
    </>
  );
}
