export type Sign = '\u2212' | '+' | ' ';

/**
 * Returns the sign of a number as a string.
 * @param n number to determine sign of
 * @returns '+' or '−' (0 maps to +)
 */

const signOf = (n: number): Sign => {
  if (n < 0) return '−';
  if (n > 0) return '+';
  return ' ';
};

export default signOf;
