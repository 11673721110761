import React from 'react';
import { Button, Input } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import type {
  ColumnApi,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from '@ag-grid-community/core';
import { MdFilterAlt } from 'react-icons/md';
import { useAtom } from 'jotai';
import uniq from 'lodash-es/uniq';
import isEqual from 'lodash-es/isEqual';

import { useGetUserSettings, useGetUsersAndProfiles, usePatchUserSettings } from 'apiHooks/Users.Hook';
import { FILTER_TYPES } from 'utils/Constants';
import { userOrganizationName, mergeSectionIdFilters } from 'utils/Helpers';
import type { AgGridUser, IUserProfile } from 'types/IUser';
import RhinoUserCacheAtom from 'store/Rhino.store';

import type {
  IColumnFilter,
  IColumnFilterText,
  IColumnFilterSet,
  IColumnFilterBoolean,
} from 'types/IFilter';
import Loading from 'components/shared/Loading/Loading';
import Filter from 'components/shared/Filter/Filter';
import SubmitButton from 'components/shared/SubmitButton';
import { useGetOrganizationSections } from 'apiHooks/OrganizationSections.Hook';
import type { INullSection, IOrganizationSections } from 'types/IOrganizationSections';
import UserAdminAgGridColDefsAtom from './UserAdminAgGridColDefs.store';
import { UserAdminFilterAtom, UserAdminInitialFilterAtom } from './UserAdminFilter.store';

import AddUserModal from './AddNewUser';
import { AG_GRID_USER_ADMIN_FIELDS } from './UserAdminTableColumns';
import { UserAdminColumnsAtom } from './UserAdminColumns.store';

const isFilterApplied = () => (true);

const passesTextFilter = (textFilter: IColumnFilterText, colData: string) => (
  !textFilter.filterValue.trim() || (colData || '').toUpperCase().includes(textFilter.filterValue.toUpperCase())
);

const passesClaimsBooleanFilter = (booleanFilter: IColumnFilterBoolean, colData?: string[]) => (
  Array.isArray(colData)
  && Array.isArray(booleanFilter.filterValues)
  && ((colData[0] === 'admin' && booleanFilter.filterValues[0] === true)
    || (colData[0] === undefined && booleanFilter.filterValues[1] === true)
    || (booleanFilter.filterValues[1] === booleanFilter.filterValues[0]))
);

const passesBooleanFilter = (booleanFilter: IColumnFilterBoolean, colData: boolean) => (
  ((booleanFilter.options[0] === colData && booleanFilter.filterValues[0] === true)
    || (booleanFilter.options[1] === colData && booleanFilter.filterValues[1] === true)
    || (booleanFilter.filterValues[1] === booleanFilter.filterValues[0]))
);

const passesSetFilter = (setFilter: IColumnFilterSet, colData: string | number) => {
  const idx = setFilter.options.indexOf(colData);

  return idx === -1 || setFilter.filterValues[idx] === true;
};

function UserAdmin() {
  const [showFilterMenu, setShowFilterMenu] = React.useState(false);
  const [rows, setRows] = React.useState<AgGridUser[]>([]);
  const [gridApi, setGridApi] = React.useState<GridApi | undefined>();
  const [userSettingsLoaded, setUserSettingsLoaded] = React.useState(false);
  const [columnApi, setColumnApi] = React.useState<ColumnApi>();
  const [rhinoUserCache, setRhinoUserCache] = useAtom(RhinoUserCacheAtom);
  const [columnDefs] = useAtom(UserAdminAgGridColDefsAtom);
  const [appliedFilter, setAppliedFilter] = useAtom(UserAdminFilterAtom);
  const [columnsState, setColumnsState] = useAtom(UserAdminColumnsAtom);
  const [userAdminInitialFilter, setUserAdminInitialFilter] = useAtom(UserAdminInitialFilterAtom);
  const [
    {
      data: usersData,
      isLoading: usersLoading,
      isError: isUsersError,
      error: usersError,
    },
    {
      data: profilesData,
      isLoading: profilesLoading,
      isError: isProfilesError,
      error: profilesError,
    },
  ] = useGetUsersAndProfiles();

  const {
    data: sections,
    isLoading: sectionsLoading,
    isError: isSectionsError,
    error: sectionsError,
  } = useGetOrganizationSections();
  const {
    data: userSettings,
    isLoading: userSettingsLoading,
  } = useGetUserSettings();

  const isLoading = usersLoading || profilesLoading || sectionsLoading || userSettingsLoading;
  // const isRefetching = usersRefetching || profilesRefetching;
  const isError = isUsersError || isProfilesError || isSectionsError;

  const patchUserSettings = usePatchUserSettings();

  const onColumnMoved = () => {
    setColumnsState(columnApi?.getColumnState());
  };

  const saveGridView = async () => (
    patchUserSettings([
      { field: 'userAdminFilter', value: appliedFilter },
      { field: 'userAdminColumnsState', value: columnApi?.getColumnState() },
    ])
  );

  React.useEffect(() => {
    if (!userSettings || userSettingsLoaded) return;

    const { userAdminFilter, userAdminColumnsState } = userSettings;

    if (userAdminFilter
      && !Array.isArray(userAdminFilter)
      && Object.keys(userAdminFilter).length > 0
      && appliedFilter.sectionId
      && userAdminFilter.sectionId) {
      setAppliedFilter({
        ...userAdminFilter,
        sectionId: mergeSectionIdFilters(appliedFilter.sectionId, userAdminFilter.sectionId),
      });
    }
    if (columnsState) {
      columnApi?.applyColumnState({
        state: columnsState,
        applyOrder: true,
      });
    } else if (userAdminColumnsState) {
      columnApi?.applyColumnState({
        state: userAdminColumnsState,
        applyOrder: true,
      });
    }
    setUserSettingsLoaded(true);
  }, [
    columnApi,
    columnsState,
    setAppliedFilter,
    userSettings,
    appliedFilter,
    userSettingsLoaded,
  ]);

  React.useEffect(() => {
    if (rows.length > 0) {
      const sectionIds = uniq(rows?.map((row) => row.sectionId)).filter(Boolean);
      const filteredSections: [INullSection, ...IOrganizationSections[]] | undefined = (
        sections
          ? [
            {
              sectionId: null,
              sectionName: 'No Section',
            },
            ...sections.filter(
              (x) => sectionIds.includes(x.sectionId),
            ),
          ]
          : undefined
      );

      const filterValues: boolean[] = new Array(filteredSections?.length).fill(true);

      // XXX - prevent race condition crashing the page
      if (!filteredSections) {
        return;
      }

      setAppliedFilter({
        ...appliedFilter,
        sectionId: {
          ...appliedFilter.sectionId,
          options: filteredSections?.map((s) => s.sectionId),
          labels: filteredSections?.map((s) => s.sectionName),
          filterValues,
        } as IColumnFilterSet,
      });
      setUserAdminInitialFilter({
        ...appliedFilter,
        sectionId: {
          ...appliedFilter.sectionId,
          options: filteredSections?.map((s) => s.sectionId),
          labels: filteredSections?.map((s) => s.sectionName),
          filterValues,
        } as IColumnFilterSet,
      });
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    rows,
    setAppliedFilter,
    setUserAdminInitialFilter,
  ]);

  React.useEffect(() => {
    const visibleCols = AG_GRID_USER_ADMIN_FIELDS.filter((key) => !appliedFilter[key]?.isHidden);
    const hiddenCols = AG_GRID_USER_ADMIN_FIELDS.filter((key) => appliedFilter[key]?.isHidden);
    columnApi?.setColumnsVisible(visibleCols, true);
    columnApi?.setColumnsVisible(hiddenCols, false);
    gridApi?.onFilterChanged();
  }, [appliedFilter, gridApi, columnApi]);

  React.useEffect(() => {
    if (!rhinoUserCache.UserAdminAgGridColDefsAtom
      || !rhinoUserCache.UserAdminFilterAtom
      || !rhinoUserCache.UserAdminInitialFilterAtom) {
      setRhinoUserCache((prev) => ({
        ...prev,
        UserAdminAgGridColDefsAtom,
        UserAdminFilterAtom,
        UserAdminInitialFilterAtom,
      }));
    }
  }, [rhinoUserCache]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!(!!profilesData && !!usersData)) return;
    const profileMap = profilesData.reduce<Record<string, IUserProfile>>(
      (agg, curr) => ({
        ...agg,
        [curr.id]: curr,
      }),
      {},
    );

    const agGridUsers = usersData.map<AgGridUser>(
      (user) => ({
        ...user,
        ...profileMap[user.userId],
      }),
    );

    setRows(agGridUsers);
    gridApi?.setRowData(agGridUsers);
  }, [gridApi, profilesData, usersData]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <>
        <div>{usersError as string}</div>
        <div>{profilesError as string}</div>
        <div>{sectionsError as string}</div>
      </>
    );
  }

  if (!rows?.[0]) {
    return <div>No Data</div>;
  }

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const externalFilter = (node: IRowNode<AgGridUser>) => (
    AG_GRID_USER_ADMIN_FIELDS.reduce((isDisplayed, key) => {
      const agGridUserKey = key;
      const colFilter: IColumnFilter | undefined = appliedFilter[key];
      const nodeData = node.data;

      if (!isDisplayed || colFilter === undefined || colFilter.isHidden || nodeData === undefined) {
        return isDisplayed;
      }

      if (colFilter.filterType === FILTER_TYPES.TEXT) {
        return passesTextFilter(colFilter, nodeData[agGridUserKey] as string);
      }

      if (colFilter.filterType === FILTER_TYPES.BOOLEAN) {
        const colData = nodeData[agGridUserKey] as boolean;

        if (key === 'claims') {
          return passesClaimsBooleanFilter(colFilter, nodeData?.claims);
        }
        return passesBooleanFilter(colFilter, colData);
      }

      if (colFilter.filterType === FILTER_TYPES.SET) {
        return passesSetFilter(colFilter, nodeData[agGridUserKey] as string | number);
      }

      return isDisplayed;
    }, true)
  );

  const onSearchQueryChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    gridApi?.setQuickFilter(e.target.value);
  };

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100">
      <h2 className="text-secondary px-3">
        {`${userOrganizationName()}: All Users`}
      </h2>
      <div className="d-flex justify-content-between bg-dark bg-gradient rounded-top p-3">
        <AddUserModal />

        <Input
          className="w-50"
          placeholder="Search..."
          onChange={onSearchQueryChange}
        />

        <div className="d-flex gap-3">
          <SubmitButton
            title="Save filter options and column order"
            onSubmit={saveGridView}
            canSubmit
          >
            Save View
          </SubmitButton>

          <Button
            title="Filter Users"
            className="align-self-center border-0 bg-transparent"
            onClick={() => setShowFilterMenu(!showFilterMenu)}
          >
            <MdFilterAlt className={(isEqual(appliedFilter, userAdminInitialFilter) ? 'text-white' : 'text-primary')} />
          </Button>
        </div>
      </div>
      <div className="ag-theme-material w-100 h-100">

        <Filter filterName="Users" isOpened={showFilterMenu} />

        <AgGridReact
          onGridReady={onGridReady}
          rowData={rows}
          columnDefs={columnDefs}
          animateRows
          maintainColumnOrder
          isExternalFilterPresent={isFilterApplied}
          doesExternalFilterPass={externalFilter}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          onColumnMoved={onColumnMoved}
        />
      </div>
    </div>
  );
}

export default UserAdmin;
