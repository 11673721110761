import { useQuery } from '@tanstack/react-query';

import { userOrganizationId } from 'utils/Helpers';
import type { IOverview } from 'types/IOverview';
import { authHttpGet } from 'store/authHttp.store';

const useGetOverview = () => (
  useQuery({
    queryKey: ['GetOverview'],
    queryFn: () => (
      authHttpGet<IOverview>(
        `/data/v1/organizations/${userOrganizationId()}/fleet`,
      )),
  })
);

export default useGetOverview;
