import type { IUserNameResponse } from 'types/IUser';

const AgGridUserNameResponseComparator = (valA: IUserNameResponse, valB: IUserNameResponse) => {
  // cSpell: disable
  if (valA.fname !== valB.fname) {
    return ((valA.fname || '') > (valB.fname || '') ? -1 : 1);
  }
  if (valA.gname !== valB.gname) {
    return ((valA.gname || '') > (valB.gname || '') ? -1 : 1);
  }
  // cSpell: enable

  return ((valA.rank || '') > (valB.rank || '') ? -1 : 1);
};

export default AgGridUserNameResponseComparator;
