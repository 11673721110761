import React from 'react';
import {
  Button,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { useGetOrganizations } from 'apiHooks/Organizations.Hook';
import { usePatchVehicle } from 'apiHooks/Vehicles.Hook';
import OrganizationsSelect from 'components/fields/OrganizationsSelect';
import RhinoModal, { RhinoModalBody, RhinoModalContainer, RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';

interface IEditOrganizationModal {
  orgId: number | undefined,
  regNumber: string,
}

function EditOrganizationModal({ orgId, regNumber }: IEditOrganizationModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [organizationName, setOrganizationName] = React.useState<string>('');
  const [tmpOrganizationId, setTmpOrganizationId] = React.useState(orgId);
  const navigate = useNavigate();
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetOrganizations();
  const patchVehicle = usePatchVehicle();

  const restrictUpdates = useRestrictUpdates();

  React.useEffect(() => {
    if (data) {
      setOrganizationName(data.find((x) => x.id === orgId)?.name || '');
    }
  }, [data, orgId]);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>{ error as string }</div>;
  }

  const toggle = () => {
    setModalOpen(!modalOpen);
    setConfirmModalOpen(false);
  };

  const resetState = () => setTmpOrganizationId(orgId);

  const canSubmit = (
    !!tmpOrganizationId
    && tmpOrganizationId !== orgId
  );

  const onSubmit = async () => {
    if (!canSubmit) {
      return;
    }

    await patchVehicle(regNumber, { orgId: tmpOrganizationId })
      .then(() => {
        toggle();
        navigate('/FleetDetails');
      });
  };

  return (
    <>
      <div className="col d-flex align-items-center">
        {
          organizationName
            ? <span className="text-nowrap">{organizationName}</span>
            : <span className="text-nowrap text-muted">No Organization selected</span>
        }
        {!restrictUpdates && (
          <Button color="link" onClick={toggle} className="py-0">
            <MdEdit />
          </Button>
        )}
      </div>
      <RhinoModalContainer
        isOpen={modalOpen}
        toggle={toggle}
        onClosed={resetState}
      >
        <RhinoModalHeader toggle={toggle}>
          Organization reassignment for
          { ' ' }
          { regNumber }
        </RhinoModalHeader>
        <RhinoModalBody>
          <OrganizationsSelect
            id={`inputOrganizationNumber-${regNumber}`}
            onValueChange={(org) => {
              setTmpOrganizationId(org?.id);
            }}
            defaultValue={orgId}
            isInvalid={!tmpOrganizationId}
          />
        </RhinoModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => setConfirmModalOpen(true)}
            disabled={!tmpOrganizationId || tmpOrganizationId === orgId}
          >
            Update
          </Button>
        </ModalFooter>
      </RhinoModalContainer>

      <RhinoModal
        centered
        title={`Confirm organization reassignment for ${regNumber}`}
        isOpen={confirmModalOpen}
        setIsOpen={setConfirmModalOpen}
        canSubmit
        onSubmit={onSubmit}
      >
        Are you sure you want to transfer this vehicle to the
        {' '}
        {data.find((x) => x.id === tmpOrganizationId)?.name || ''}
        ?
      </RhinoModal>
    </>
  );
}

export default EditOrganizationModal;
