import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import type {
  ColDef,
} from '@ag-grid-community/core';

import AgGridDateValueFormatter from 'components/agGridRenderers/AgGridDateValueFormatter';
import type { ITdyCommon } from 'types/IOverview';

function ApproachingTDYs({ rows }: { rows: ITdyCommon[] }) {
  const [columnDefs] = React.useState<ColDef[]>([
    {
      field: 'regNumber',
      headerName: 'Reg Number',
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      valueFormatter: AgGridDateValueFormatter,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      valueFormatter: AgGridDateValueFormatter,
    },
    {
      field: 'daysToTdy',
      headerName: 'Days to TDY',
    },
  ]);

  return (
    <div className="ag-theme-material w-100 h-100">
      <AgGridReact
        rowData={rows}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
      />
    </div>
  );
}

export default ApproachingTDYs;
