import React from 'react';
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { FaRegTimesCircle } from 'react-icons/fa';
import { nanoid } from 'nanoid';
import uniqBy from 'lodash-es/uniqBy';

import type { IFleetDetail } from 'types/IFleetDetails';
import type { IVehicleCreationPost, IVehicleCreationEphemeral } from 'types/IVehicleDetails';

import VehicleTypesSelect from 'components/fields/VehicleTypesSelect';
import OrganizationSectionsSelect from 'components/fields/OrganizationSectionsSelect';
import WorkflowsSelect from 'components/fields/WorkflowsSelect';

import { usePostNewVehicles } from 'apiHooks/Vehicles.Hook';
import { userOrganizationId, userOrganizationName } from 'utils/Helpers';

import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import useRestrictUpdates from 'utils/helpers/useRestrictUpdates.Helper';
import { useGetVehicleTypes, usePostVehicleType } from 'apiHooks/VehicleTypes.Hook';
import { uniq } from 'lodash-es';
import { useGetOrganizationSections } from 'apiHooks/OrganizationSections.Hook';
import { Link } from 'react-router-dom';
import organizationAdministrationRoute from 'views/OrganizationAdministration/Route';
import styles from './AddVehiclesModal.module.css';

const emptyEphemeralVehicleInitialization = (): IVehicleCreationEphemeral[] => ([{
  id: nanoid(),
  make: null, // eslint-disable-line unicorn/no-null
  model: null, // eslint-disable-line unicorn/no-null
  orgId: (userOrganizationId() || 0),
  regNumber: undefined,
  sectionId: undefined,
  vehicleType: undefined,
  workflowId: undefined,
  wrm: false,
  year: null, // eslint-disable-line unicorn/no-null
}]);

/**
 * for validation and submission
 * matches only regNumbers that are allowed
 * * 4-15 characters
 * * must start and end with an alphanumeric char
 * * otherwise allows alphanumeric characters along with dash, underscore, and space
 * * cannot contain any repeating non-alphanumeric chars
 */
const regNumberRegEx = (
  /^[\dA-Za-z](?:(?![ _-]{2})[\w -]){2,13}[\dA-Za-z]$/
);

/**
 * for entry mask
 * matches everything above with the following additions:
 * * matches less than 4 chars
 * * does not require last char to be alphanumeric
 * *
 */
const partialRegNumberRegEx = (
  /^(|(?=.{0,15}$)[\dA-Za-z](?:(?![ _-]{2})[\w -])*)$/
);

const regNumberValid = (regNumber: string | undefined) => (
  regNumber
    ? regNumberRegEx.test(regNumber)
    : false
);

function RegNumberExistsAlert({ regNumber, fleetDetails }: {
  regNumber: string | undefined;
  fleetDetails: IFleetDetail[];
}) {
  const existingVehicle = fleetDetails?.find((vehicle) => (
    vehicle.regNumber.toLowerCase() === regNumber?.toLowerCase()
  ));

  if (!existingVehicle) return null;

  const vehicleIsDecommissioned = existingVehicle.decommissionDate !== null;

  if (vehicleIsDecommissioned) {
    return (
      <Alert color="danger">
        {
          `Registration number ${regNumber} already exists for a decommissioned vehicle.
        You may redesignate the vehicle as in-commission `
        }
        <Link to={`/Vehicle/${regNumber}`} target="_blank">
          here.
        </Link>
      </Alert>
    );
    // TODO: add a link to the decommissioned vehicle page to re-commission
  }
  return (
    <Alert color="danger">
      {`Registration number ${regNumber} already exists for this organization`}
    </Alert>
  );
}

function AddVehiclesModal({ fleetDetails }: { fleetDetails: IFleetDetail[]; }) {
  const restrictUpdates = useRestrictUpdates();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [newVehicles, setNewVehicles] = React.useState<IVehicleCreationEphemeral[]>(
    emptyEphemeralVehicleInitialization(),
  );
  const { data: vehicleTypes } = useGetVehicleTypes();
  const { data: sections } = useGetOrganizationSections();
  const postVehicleType = usePostVehicleType();
  const postNewVehicles = usePostNewVehicles();

  const newVehicleTypes = uniq(
    newVehicles.map(
      (v) => v.vehicleType,
    ),
  ).filter((vt) => !!vt && !vehicleTypes?.includes(vt)) as string[];

  const resetState = () => setNewVehicles(emptyEphemeralVehicleInitialization());

  const onSubmit = async () => {
    if (newVehicleTypes[0]) {
      await Promise.all(
        newVehicleTypes.map(
          (vehicleType) => (
            postVehicleType(vehicleType)
          ),
        ),
      );
    }
    await postNewVehicles(
      newVehicles.map((tmpVehicle): IVehicleCreationPost => {
        const { id, ...vehicle } = tmpVehicle;
        return {
          ...vehicle,
          vehicleType: vehicle.vehicleType || '',
          regNumber: vehicle.regNumber || '',
          sectionId: vehicle.sectionId || null, // eslint-disable-line unicorn/no-null
          workflowId: vehicle.workflowId || 0,
        };
      }),
    );
  };

  const regNumberExists = (regNumber: string) => (
    regNumber && (fleetDetails || []).some((vehicle) => (
      vehicle.regNumber.toLowerCase() === regNumber.toLowerCase()
    )));

  const regNumberDuplicated = () => uniqBy(newVehicles, 'regNumber').length !== newVehicles.length;

  const isValid = () => (newVehicles.length > 0 && !newVehicles.some((newVehicle) => (
    !newVehicle.vehicleType
    || !newVehicle.sectionId
    || !newVehicle.workflowId
    || !newVehicle.orgId
    || regNumberExists(newVehicle.regNumber || '')
    || !regNumberValid(newVehicle.regNumber)
  )));

  const newVehicleRow = () => {
    if (newVehicles.length === 0) {
      setNewVehicles(emptyEphemeralVehicleInitialization());
    } else {
      setNewVehicles([
        ...newVehicles,
        {
          ...newVehicles[newVehicles.length - 1],
          id: nanoid(),
          regNumber: undefined,
        } as IVehicleCreationEphemeral,
      ]);
    }
  };

  const removeNewVehicle = (indexToRemove: number) => {
    setNewVehicles(newVehicles.filter((_, i) => i !== indexToRemove));
  };

  const modalContent = (
    <>
      {
        newVehicles.map((newVehicle, idx) => (
          <div className="d-grid" key={newVehicle.id}>
            <div className="d-flex align-items-center gap-2">
              <div className="form-floating">
                <input
                  className="form-control mb-3"
                  id={`inputRegNumber-${idx}`}
                  autoComplete="off"
                  value={newVehicle.regNumber}
                  onChange={(event) => {
                    const tmpVehicle = [...newVehicles];
                    const regNum = event.target.value;

                    if (partialRegNumberRegEx.test(regNum)) {
                      (tmpVehicle[idx] as IVehicleCreationEphemeral).regNumber = regNum;
                      setNewVehicles(tmpVehicle);
                    }
                  }}
                  required
                  placeholder=" "
                />
                <label htmlFor={`inputRegNumber-${idx}`}>
                  Registration Number
                </label>
              </div>
              <OrganizationSectionsSelect
                id={`inputSectionNumber-${idx}`}
                onValueChange={(sectionId) => {
                  const tmpVehicle = [...newVehicles];
                  (tmpVehicle[idx] as IVehicleCreationEphemeral).sectionId = sectionId;
                  setNewVehicles(tmpVehicle);
                }}
                defaultValue={newVehicle.sectionId || ''}
                isInvalid={!newVehicles[idx]?.sectionId}
              />
              <VehicleTypesSelect
                id={nanoid()}
                onValueChange={(vehicleType) => {
                  const tmpVehicle = [...newVehicles];
                  (tmpVehicle[idx] as IVehicleCreationEphemeral).vehicleType = vehicleType;
                  setNewVehicles(tmpVehicle);
                }}
                defaultValue={newVehicle.vehicleType || ''}
                isInvalid={!newVehicles[idx]?.vehicleType}
                allowNew
              />
              <WorkflowsSelect
                id={`inputWorkflowNumber-${idx}`}
                onValueChange={(workflow) => {
                  const tmpVehicle = [...newVehicles];
                  (tmpVehicle[idx] as IVehicleCreationEphemeral).workflowId = workflow;
                  setNewVehicles(tmpVehicle);
                }}
                defaultValue={newVehicle.workflowId || ''}
                isInvalid={!newVehicles[idx]?.workflowId}
              />
              <div className="d-flex flex-column justify-content-center mb-3">
                <FormGroup
                  check
                  inline
                >
                  <Label check>
                    <Input
                      id={`wrmCheckbox-${idx}`}
                      type="checkbox"
                      defaultChecked={newVehicle.wrm || false}
                      onChange={(e) => {
                        const tmpVehicle = [...newVehicles];
                        (tmpVehicle[idx] as IVehicleCreationEphemeral).wrm = e.target.checked;
                        setNewVehicles(tmpVehicle);
                      }}
                    />
                    WRM
                  </Label>
                </FormGroup>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  onClick={() => removeNewVehicle(idx)}
                  className="py-0 mb-3"
                  color="link"
                >
                  <FaRegTimesCircle className="text-danger fs-5" />
                </Button>
              </div>

            </div>
            <RegNumberExistsAlert
              regNumber={newVehicle.regNumber}
              fleetDetails={fleetDetails}
            />
          </div>
        ))
      }

      {
        newVehicles.some((newVehicle) => (
          newVehicle.regNumber && !regNumberValid(newVehicle.regNumber)
        )) && <Alert color="danger">Invalid registration number</Alert>
      }
      {
        regNumberDuplicated() && <Alert color="danger">New Registration number duplicated</Alert>
      }
      <div>
        <Button
          color="primary"
          onClick={newVehicleRow}
        >
          New Vehicle
        </Button>
      </div>
    </>
  );

  return (
    <>
      <Button disabled={restrictUpdates} onClick={() => setModalOpen(true)}>
        Add Vehicle(s)
      </Button>
      <RhinoModal
        scrollable
        title={`New Vehicle${newVehicles.length > 1 ? 's' : ''} for ${userOrganizationName()}`}
        size="xl"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={isValid()}
        onSubmit={onSubmit}
        onClosed={resetState}
        bodyClassName={styles['modal-body']}
      >
        {
          sections?.[0]
            ? modalContent
            : (
              <div className="d-flex flex-column gap-2">
                <div>
                  No sections are defined for this organization.
                  Before adding vehicles, define a section in the Administration view.
                </div>
                <Link to={organizationAdministrationRoute[0].path}>
                  <Button>
                    Go to Administration
                  </Button>
                </Link>
              </div>
            )
        }
      </RhinoModal>
    </>
  );
}

export default AddVehiclesModal;
