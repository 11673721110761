import React from 'react';
import { Spinner } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import type { ColDef } from '@ag-grid-community/core';

import { useGetVehicleInspectionHistory } from 'apiHooks/Vehicles.Hook';
import AgGridBooleanIconCellRenderer from 'components/agGridRenderers/AgGridBooleanIconCellRenderer';
import AgGridVehicleInspectionResultsLinkCellRenderer from 'components/agGridRenderers/AgGridVehicleInspectionResultsLinkCellRenderer';
import AgGridAf1800DownloadCellRenderer from 'components/agGridRenderers/AgGridAf1800DownloadCellRenderer';
import type { IInspectionCommon } from 'types/IVehicleInspections';

interface IInspectionCols extends IInspectionCommon {
  pdf: unknown,
}

const initialColumns: ColDef<Partial<IInspectionCols>>[] = [
  {
    field: 'inspectionDate',
    headerName: 'Inspection Date',
    cellRenderer: AgGridVehicleInspectionResultsLinkCellRenderer,
  }, {
    field: 'shift',
    headerName: 'Shift',
  }, {
    field: 'inspector',
    headerName: 'Inspector',
  }, {
    field: 'passed',
    headerName: 'Passed',
    cellRenderer: AgGridBooleanIconCellRenderer,
    width: 100,
  }, {
    field: 'pdf',
    headerName: 'Monthly AF-1800',
    cellRenderer: AgGridAf1800DownloadCellRenderer,
    width: 175,
    sortable: false,
  },
];

function VehicleInspections({ regNumber }: { regNumber: string }) {
  const [columnDefs] = React.useState(initialColumns);
  const { isLoading, isError, data } = useGetVehicleInspectionHistory(regNumber);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>No inspections have been recorded for this vehicle</div>;
  }

  return (
    <div className="ag-theme-material h-100 w-100">
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        animateRows
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
      />
    </div>
  );
}

export default VehicleInspections;
