import {
  PDFDocument,
  type PDFForm,
} from 'pdf-lib';
import { decode } from 'html-entities';

import type {
  IAf1800Result,
  InspectionsType,
  ShiftInspections,
  IAf1800Page1,
  IAf1800Page2,
  IAf1800Page3,
  IAf1800RuleEntry,
  ICheckField,
} from 'types/IAF1800Pdf';
import { formatDate } from 'utils/Helpers';
import { format } from 'date-fns';

const COLUMN2_OFFSET = 19;
const COLUMN3_OFFSET = 38;

const af1800PdfFieldMap: Record<number, ICheckField> = {
  36: { check: 'AF1800_unlocked[0].Page1[0].CHECK36[0]', entry: 'AF1800_unlocked[0].Page1[0].FIELD9[0]' },
  37: { check: 'AF1800_unlocked[0].Page1[0].CHECK37[0]', entry: 'AF1800_unlocked[0].Page1[0].FIELD10[0]' },
  38: { check: 'AF1800_unlocked[0].Page1[0].CHECK38[0]', entry: 'AF1800_unlocked[0].Page1[0].FIELD11[0]' },
  39: { check: 'AF1800_unlocked[0].Page1[0].CHECK39[0]', entry: 'AF1800_unlocked[0].Page1[0].FIELD12[0]' },
};

const toInspectionArray = (value:InspectionsType) => {
  if (Array.isArray(value)) {
    return value;
  }

  return Object.keys(value).reduce((shiftInspections: ShiftInspections[], key: string | number) => {
    const numberKey = typeof key === 'string' ? Number.parseInt(key, 10) : key;

    if (numberKey !== undefined) {
      const tmpInspections = [...shiftInspections];

      tmpInspections[numberKey] = value[key] || [];
      return tmpInspections;
    }

    return shiftInspections;
  }, []);
};

const toPage3Array = (value:IAf1800Page3) => {
  if (Array.isArray(value)) {
    return value;
  }

  return Object.keys(value).reduce((ruleEntries: IAf1800RuleEntry[], key: string | number) => {
    const numberKey = typeof key === 'string' ? Number.parseInt(key, 10) : key;

    if (numberKey !== undefined && value[key] !== undefined) {
      const tmpEntries = [...ruleEntries];

      tmpEntries[numberKey] = value[key] || { isChecked: false, text: '' };
      return tmpEntries;
    }

    return ruleEntries;
  }, []);
};

const populatePage1 = (form: PDFForm, page1: IAf1800Page1) => {
  const vehicleType = form.getTextField('AF1800_unlocked[0].Page1[0].VehicleTypeField[0]');
  vehicleType.setFontSize(7);
  if (page1.vehicleType) {
    vehicleType.setText(decode(page1.vehicleType));
  }
  // set regNumber
  const regNumber = form.getTextField('AF1800_unlocked[0].Page1[0].FIELD3[0]');
  regNumber.setText(page1.regNumber);

  // set organization
  const organization = form.getTextField('AF1800_unlocked[0].Page1[0].FIELD4[0]');
  organization.setText(page1.organization || '');

  // set location
  const location = form.getTextField('AF1800_unlocked[0].Page1[0].FIELD5[0]');
  location.setText(page1.location || '');

  // set basePhoneNo
  const basePhoneNo = form.getTextField('AF1800_unlocked[0].Page1[0].FIELD6[0]');
  if (page1.basePhoneNo) {
    basePhoneNo.setText(page1.basePhoneNo);
  }

  // set vcoName
  const vcoName = form.getTextField('AF1800_unlocked[0].Page1[0].FIELD7[0]');
  vcoName.setText(page1.vcoName || '');

  // set vco phone
  const vcoPhone = form.getTextField('AF1800_unlocked[0].Page1[0].FIELD8[0]');
  if (page1.vcoPhoneNo) {
    vcoPhone.setText(page1.vcoPhoneNo);
  }

  // set date
  const dateField = form.getTextField('AF1800_unlocked[0].Page1[0].DateField[0]');
  dateField.setText(page1.date);

  /** inspections */
  toInspectionArray(page1.inspections).forEach((inspection, i) => {
    if (i > 0 && inspection.length === 3) {
      inspection.forEach((ins, j) => {
        const fieldName = `AF1800_unlocked[0].Page2[0].INS${i}[${j}]`;
        if (fieldName !== 'AF1800_unlocked[0].Page2[0].INS31[2]') {
          const insData = form.getTextField(`AF1800_unlocked[0].Page2[0].INS${i}[${j}]`);
          insData.setText(decode(ins) || '');
        }
      });
    }
  });

  return form;
};

const populatePage2Part1 = (form:PDFForm, page2: IAf1800Page2) => {
  /* monthlyRequirement */
  const dateFormat = 'dd/MM/yyyy';

  const { milesHours, tirePressure } = page2.monthlyRequirement;

  // milesHours/date
  const milesHoursDate = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD1_2[0]');
  if (milesHours.date) {
    milesHoursDate.setText(
      format(new Date(milesHours.date), dateFormat),
    );
  }

  // milesHours/miles
  const miles = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD2_2[0]');
  miles.setText(milesHours.miles ? milesHours.miles.toString() : ' ');

  // milesHours/hours
  const hours = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD3_2[0]');
  hours.setText(milesHours.hours ? milesHours.hours.toString() : '');

  // milesHours/operator
  const operator = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD6_2[0]');
  const operatorTimestamp = formatDate(
    milesHours.date,
  );
  operator.setText(`${milesHours.operator || ''} \t ${operatorTimestamp}`);

  // tirePressure/frontPsi
  const frontPsi = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD4_2[0]');
  frontPsi.setText(tirePressure.frontPsi ? tirePressure.frontPsi.toString() : '');

  // tirePressure/rearPsi
  const rearPsi = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD5_2[0]');
  rearPsi.setText(tirePressure.rearPsi ? tirePressure.rearPsi.toString() : '');

  // Beginning of Month Date
  const monthlyDate = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD7_2[0]');
  if (milesHours.date) {
    monthlyDate.setText(
      format(new Date(milesHours.date), dateFormat),
    );
  }

  // tirePressure/operator
  const tirePressureOperator = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD8_2[0]');
  const operatorTirePressureTimestamp = formatDate(
    tirePressure.date,
  );
  tirePressureOperator.setText(`${tirePressure.operator || ''} \t ${operatorTirePressureTimestamp}`);

  // tirePressure/date
  const tirePressureDate = form.getTextField('AF1800_unlocked[0].Page2[0].FIELD9_2[0]');
  if (tirePressure.date) {
    tirePressureDate.setText(
      format(new Date(tirePressure.date), dateFormat),
    );
  }

  return form;
};

const populatePage2Part2 = (form:PDFForm, page2: IAf1800Page2) => {
  const maintenance = page2.maintenance.slice(0, 18);

  maintenance.forEach((item, i: number) => {
    const { operatorsReport, reportedToMx, mxReportStatus } = item;
    const col1Offset = i + 1;
    const col2Offset = col1Offset + COLUMN2_OFFSET;
    const col3Offset = col1Offset + COLUMN3_OFFSET;

    const fieldName1 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col1Offset}[0]`);
    const fieldName2 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col1Offset}[1]`);
    const fieldName3 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col1Offset}[2]`);

    fieldName1.setText(decode(String(operatorsReport.itemNo)));
    fieldName2.setText(decode(operatorsReport.discrepancy));
    if (operatorsReport.date) {
      fieldName3.setText(format(new Date(operatorsReport.date), 'dd/MM/yy'));
    }

    const fieldName4 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col2Offset}[0]`);
    const fieldName5 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col2Offset}[1]`);
    const fieldName6 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col2Offset}[2]`);

    fieldName4.setFontSize(7);
    if (reportedToMx.date) {
      fieldName4.setText(format(new Date(reportedToMx.date), 'dd/MM/yy HH:mm'));
    }

    if (reportedToMx.milesHours) {
      fieldName5.setText(decode(reportedToMx.milesHours));
    }
    fieldName6.setText(decode(reportedToMx.name ? String(reportedToMx.name) : ''));

    const fieldName7 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col3Offset}[0]`);
    const fieldName8 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col3Offset}[1]`);
    const fieldName9 = form.getTextField(`AF1800_unlocked[0].Page2[0].ITEM${col3Offset}[2]`);

    fieldName7.setText(decode(mxReportStatus.workOrder || ''));
    fieldName8.setText(decode(mxReportStatus.date || ''));
    fieldName9.setText(decode(mxReportStatus.statusCode || ''));
  });

  return form;
};

const populatePage3 = (form:PDFForm, page3: IAf1800Page3) => {
  const fields = form.getFields();

  toPage3Array(page3).forEach((entry) => {
    fields.forEach((field) => {
      const name = field.getName();
      const itemNo = name.split('-')[1];
      if (entry.isChecked && (itemNo === String(entry.itemNo))) {
        const checkBox = form.getCheckBox(name);
        checkBox.check();
      } else if (entry.isChecked && entry.itemNo) {
        const lookup = af1800PdfFieldMap[entry.itemNo];
        if (lookup) {
          const checkBox = form.getCheckBox(lookup.check);
          checkBox.check();
          const rule = form.getTextField(lookup.entry);
          rule.setText(decode(entry.text));
        }
      }
    });
  });

  return form;
};

function populatePdfDoc(reportData: IAf1800Result, pdfFile: ArrayBuffer) {
  return PDFDocument.load(new Uint8Array(pdfFile), { ignoreEncryption: true }).then((pdfDoc) => {
    try {
      const form = pdfDoc.getForm();
      const { page1, page2, page3 } = reportData;

      if (!page1 || !page2 || !page3) {
        return pdfDoc;
      }

      // Broken down to comply with SonarJs complexity limits
      // XXX: form may be passed as reference and setting
      //      the returned form as constants may not needed.
      const page1Populated = populatePage1(form, page1);
      const page2PopulatedPart1 = populatePage2Part1(page1Populated, page2);
      const page2Populated = populatePage2Part2(page2PopulatedPart1, page2);
      populatePage3(page2Populated, page3);

      form.flatten();

      return pdfDoc;
    } catch {
      return pdfDoc;
    }
  });
}

function populateAndMergePdfDocuments(reportsData: IAf1800Result[], pdfFile: ArrayBuffer) {
  return PDFDocument.create().then((mergedPdf) => (
    Promise.all(reportsData.map((report) => (
      populatePdfDoc(report, pdfFile)
        .then((populatedPdf) => (
          mergedPdf.copyPages(populatedPdf, populatedPdf.getPageIndices())
            .then((pages) => {
              pages.forEach((page) => mergedPdf.addPage(page));
              return mergedPdf.save();
            })
        ))
    ))).then(() => mergedPdf.save())));
}

function populateSinglePdf(reportData: IAf1800Result, pdfFile: ArrayBuffer) {
  return populatePdfDoc(reportData, pdfFile).then((pdfDoc) => pdfDoc.save());
}

export {
  populateSinglePdf,
  populateAndMergePdfDocuments,
};
