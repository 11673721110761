import type { ICellRendererParams } from '@ag-grid-community/core';
import { useGetUsers } from 'apiHooks/Users.Hook';
import { formatNameAndRank } from 'utils/Helpers';

const useAgGridUserCellRenderer = <TypeWithUserId extends object>(
  userIdKey: keyof TypeWithUserId,
) => {
  const { data: users } = useGetUsers();

  return users
    ? (params: ICellRendererParams<TypeWithUserId>) => {
      const userId = params.data?.[userIdKey];
      const user = users.find((u) => u.userId === userId);
      return formatNameAndRank(user);
    }
    : undefined;
};

export default useAgGridUserCellRenderer;
