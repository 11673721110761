import React from 'react';
import {
  Alert,
  Button,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

import { usePatchSectionName } from 'apiHooks/OrganizationSections.Hook';
import { userOrganizationName } from 'utils/Helpers';
import { MAX_SECTION_NAME_LENGTH } from 'utils/Constants';
import type { IOrganization } from 'types/IOrganization';
import type { IOrganizationSectionWithMel } from 'types/IOrganizationSections';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';

interface IRenameSectionModal {
  organization: IOrganization,
  section: IOrganizationSectionWithMel,
}

function RenameSectionModal({ organization, section }: IRenameSectionModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [newSectionName, setNewSectionName] = React.useState<string>(section.sectionName);
  const patchSectionName = usePatchSectionName();

  const toggle = () => setModalOpen(!modalOpen);

  const resetState = () => {
    setNewSectionName(section.sectionName);
  };

  const onSubmit = () => patchSectionName(section.sectionId, newSectionName);

  const nameExistsInOrg = () => (
    newSectionName && organization.sections.some((orgSection) => (
      orgSection.sectionName.toLowerCase() === newSectionName.toLowerCase()
      && orgSection.sectionId !== section.sectionId
    ))
  );

  const nameTooLong = () => newSectionName.length > MAX_SECTION_NAME_LENGTH;

  const isValid = () => [
    newSectionName,
    !nameExistsInOrg(),
    !nameTooLong(),
    newSectionName !== section.sectionName,
  ].every(Boolean);

  return (
    <>
      <Button onClick={toggle} className="py-0" color="link"><MdEdit /></Button>
      <RhinoModal
        title={`Rename ${section.sectionName} for ${userOrganizationName()}`}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={isValid()}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            id="inputSectionNam"
            className="form-control"
            defaultValue={section.sectionName}
            onChange={(event) => setNewSectionName(event.target.value.trim())}
            placeholder=" "
            maxLength={MAX_SECTION_NAME_LENGTH}
          />
          <label htmlFor="inputSectionName">
            Rename Section
          </label>
        </div>
        {
          newSectionName.length > 0 && nameExistsInOrg()
          && <Alert color="danger">Another section with this name exists for this organization.</Alert>
        }
        {
          nameTooLong() && <Alert color="danger">{`Name must be less than ${MAX_SECTION_NAME_LENGTH} characters in length.`}</Alert>
        }
      </RhinoModal>
    </>
  );
}

export default RenameSectionModal;
