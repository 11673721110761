import { decodeJwt } from '@stonewall-defense/swd-ui-utils';
import { Cookies } from 'react-cookie';
import type { RhinoCookieTokens } from 'types/Authorization';

import type { IJwtToken } from 'types/IJwt';
import { COOKIE_NAME } from 'utils/Constants';

const cookies = new Cookies();

const parseToken = () => (
  cookies.get<RhinoCookieTokens | undefined>(COOKIE_NAME)
);

const authToken = () => parseToken()?.['token'];
const refreshToken = () => parseToken()?.['refresh'];

const userJwtToken = () => {
  const appCookie = authToken();

  if (!appCookie || !decodeJwt(appCookie)) {
    return undefined;
  }
  return decodeJwt(appCookie) as IJwtToken;
};

/**
 *
 * @returns
 */
const userOrganizationId = () => {
  const jwtToken = userJwtToken();
  return jwtToken?.org.id || '';
};

/**
 *
 * @returns
 */
const userOrganizationName = () => {
  const jwtToken = userJwtToken();
  return jwtToken?.org.name || '';
};

export {
  authToken,
  refreshToken,
  userJwtToken,
  userOrganizationId,
  userOrganizationName,
};
