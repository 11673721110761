import React from 'react';

import {
  Alert,
  Form,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupText,
  Card,
  CardBody,
  CardFooter,
} from 'reactstrap';
import {
  useNavigate,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MdMail } from 'react-icons/md';

import logo from 'assets/img/rhino.svg';

interface IForgotPassword {
  email: string,
}

const swdHttpLogin = (
  urlPath: string,
  data?: IForgotPassword,
) => new Promise((resolve, reject) => {
  try {
    const postParameters = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    fetch(urlPath, postParameters).then(async (response) => {
      if (response.ok) {
        const responseText = await response.text();
        resolve(responseText);
      }
      reject(response);
    }).catch(reject);
  } catch (error) {
    reject(error);
  }
});

// XXX: react-hook-form does not play well with reactstrap Input.  Wait for Reactstrap-v10

function ForgotPassword() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = React.useState('');
  const [emailSubmitted, setEmailSubmitted] = React.useState('');
  const { register, handleSubmit, formState } = useForm<IForgotPassword>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const emailFormRegistration = register('email', { required: true });

  const onSubmit = (formData: IForgotPassword) => {
    swdHttpLogin('/auth/v1/reset', formData).then(() => {
      setEmailSubmitted(formData.email);
    })
      .catch(async (error: Response) => {
        const errorText = await error.text();
        setLoginError(errorText);
      });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <img src={logo} alt="Rhino" className="shadow-sm-lg p-3" />
      <Card className="col-lg-4 col-12">
        <CardBody>
          <Form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
            <InputGroup size="sm" className="mb-3">
              <InputGroupText className="mb-3 shadow-sm bg-secondary bg-gradient text-light">
                <MdMail className="h4 mb-0" />
              </InputGroupText>
              <FormGroup floating className="shadow-sm">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  autoComplete="username"
                  placeholder=" "
                  onChange={emailFormRegistration.onChange}
                  onBlur={emailFormRegistration.onBlur}
                  name={emailFormRegistration.name}
                  ref={emailFormRegistration.ref}
                />
                <Label htmlFor="floatingInput">Email address</Label>
                <div>
                  {formState.errors.email?.message}
                </div>
              </FormGroup>

            </InputGroup>
            {
              loginError && <Alert className="text-center" color="danger">{loginError.replaceAll('"', '')}</Alert>
            }
            {
              emailSubmitted
              && (
                <Alert className="text-center" color="success">
                  Reset request submitted.  Please check your email for next steps.
                </Alert>
              )
            }
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                className="px-3 py-2 shadow-sm text-dark"
                color="primary"
                disabled={!formState.isValid || emailSubmitted !== ''}
              >
                Send Email
              </Button>
            </div>
          </Form>
        </CardBody>
        <CardFooter className="d-flex justify-content-between bg-secondary bg-gradient text-light">
          <Button color="link" onClick={() => navigate('/')}>Login</Button>
          <Button color="link" onClick={() => navigate('/Register')}>Register</Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default ForgotPassword;
