import React from 'react';
import { Col } from 'reactstrap';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';

import viewRoutes from 'views/ViewRoutes';
import { userJwtToken } from 'utils/Helpers';
import useRhinoEventsWebSocket from 'hooks/useRhinoEventsWebSocket';
import SideBar from './Sidebar';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import InspectorLayout from './InspectorLayout';
import FleetUpdatesSideBar from './FleetUpdatesSideBar/FleetUpdatesSideBar';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
]);

function UserLayout() {
  const userToken = userJwtToken();

  // WebSocket for event streaming
  useRhinoEventsWebSocket();

  if (userToken?.user.role === 'inspector') {
    return <InspectorLayout />;
  }

  return (
    <div className="d-flex min-vh-100">

      <SideBar />

      <Col className="vh-100">
        <Routes>
          {
            viewRoutes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                element={route.component}
              />
            ))
          }
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Col>

      <FleetUpdatesSideBar />

    </div>
  );
}

export default UserLayout;
