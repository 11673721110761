import React from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css'; // cSpell: disable-line
import '@fontsource/roboto';
import '@fontsource/inconsolata';

import 'assets/css/theme.css';
import UserLayout from 'components/layout/UserLayout/UserLayout';
import LoginLayout from 'components/layout/LoginLayout/LoginLayout';
import { COOKIE_NAME } from 'utils/Constants';

function App() {
  const [token, setToken] = React.useState<string>();
  const [rhinoCookies] = useCookies([COOKIE_NAME]);
  const location = useLocation();

  React.useEffect(() => {
    if (rhinoCookies[COOKIE_NAME] && typeof rhinoCookies[COOKIE_NAME] !== 'string') {
      try {
        setToken(rhinoCookies[COOKIE_NAME].token);
      } catch {
        setToken('');
      }
    } else {
      setToken('');
    }
  }, [rhinoCookies]);

  // Wait for token to load to prevent resetting path
  if (token === undefined) {
    return <div />;
  }

  return (
    token && location.pathname !== '/PasswordReset'
      ? <UserLayout />
      : <LoginLayout />
  );
}

export default App;
