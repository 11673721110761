import { useQuery } from '@tanstack/react-query';
import { authHttpGet } from 'store/authHttp.store';
import type { IFailuresOverviewResponseItem, IFailuresPerVehicleResponseItem } from 'types/Failures';
import type { IExcessUsageDatum, IInspectionDatum, ITimeSeriesUsageDatum } from 'types/Insights';
import { formatQueryParams } from 'utils/Helpers';

const useGetExcessUsage = (start: Date, end: Date) => useQuery({
  queryKey: ['ExcessUsage', start, end],
  queryFn: () => authHttpGet<IExcessUsageDatum[]>(
    `/insights/usage/excess${formatQueryParams({
      start: start.toISOString(),
      end: end.toISOString(),
    })}`,
  ),
});

const useGetTimeSeriesUsage = () => useQuery({
  queryKey: ['TimeseriesUsage'],
  queryFn: () => authHttpGet<ITimeSeriesUsageDatum[]>('/insights/usage/timeseries'),
});

const useGetInspections = (start: Date, end: Date) => useQuery({
  queryKey: ['Inspections', start, end],
  queryFn: () => authHttpGet<IInspectionDatum[]>(
    `/insights/inspect${formatQueryParams({
      start: start.toISOString(),
      end: end.toISOString(),
    })}`,
  ),
});

const useGetFailuresOverview = (start: Date, end: Date) => useQuery({
  queryKey: ['FailuresOverview', start, end],
  queryFn: () => authHttpGet<IFailuresOverviewResponseItem[]>(
    `/insights/failures/overview${formatQueryParams({
      start: start.toISOString(),
      end: end.toISOString(),
    })}`,
  ),
});

const useGetFailuresPerVehicle = (start: Date, end: Date) => useQuery({
  queryKey: ['FailuresPerVehicle', start, end],
  queryFn: () => authHttpGet<IFailuresPerVehicleResponseItem[]>(
    `/insights/failures/perVehicle${formatQueryParams({
      start: start.toISOString(),
      end: end.toISOString(),
    })}`,
  ),
});

export {
  useGetExcessUsage,
  useGetTimeSeriesUsage,
  useGetInspections,
  useGetFailuresOverview,
  useGetFailuresPerVehicle,
};
