import React, { useEffect, useState } from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardImgOverlay,
  CardImg,
  Dropdown,
} from 'reactstrap';
import {
  Sidebar,
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { Twirl as Hamburger } from 'hamburger-react';

import useLogout from 'hooks/UseLogout';
import logo from 'assets/img/rhino.svg';

import { userJwtToken } from 'utils/Helpers';
import viewRoutes from 'views/ViewRoutes';
import type { IJwtToken } from 'types/IJwt';
import EditProfileModal from 'views/UserProfile/EditProfileModal';
import { FaUser } from 'react-icons/fa';
import DownloadPwaModal from 'components/shared/DownloadPwaModal';
import { useAtomValue } from 'jotai';
import styles from './Sidebar.module.css';
import {
  SIDEBAR_WIDTH as UPDATES_SIDEBAR_WIDTH,
  SIDEBAR_COLLAPSED_WIDTH as UPDATES_SIDEBAR_COLLAPSED_WIDTH,
} from './FleetUpdatesSideBar/FleetUpdatesSideBar';
import fleetUpdatesSideBarCollapsedAtom from './FleetUpdatesSideBar/FleetUpdatesSideBar.store';

const formatNameAndRankFromToken = (userToken : IJwtToken | undefined) => {
  if (!userToken) {
    return '';
  }
  const { rank, names } = userToken.user;

  return [
    rank || '',
    names.givenName || '',
    names.familyName || '',
  ].filter(Boolean).join(' ').trim();
};

const SIDEBAR_COLLAPSE_POINT = 1200;
type SideBarState = 'PERSIST_COLLAPSED' | 'PERSIST_EXPANDED' | 'AUTO';

function SideBar() {
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const toggleUserDropdownOpen = () => setUserDropdownOpen((b) => !b);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [pwaDownloadModalOpen, setPwaDownloadModalOpen] = useState(false);
  const userToken = userJwtToken();
  const logout = useLogout();
  const navigate = useNavigate();
  const fleetUpdatesSideBarCollapsed = useAtomValue(fleetUpdatesSideBarCollapsedAtom);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const contentWidth = windowWidth - (
    fleetUpdatesSideBarCollapsed
      ? UPDATES_SIDEBAR_COLLAPSED_WIDTH
      : UPDATES_SIDEBAR_WIDTH
  );
  const [sideBarState, setSideBarState] = useState<SideBarState>('AUTO');
  const [collapsed, setCollapsed] = useState(false);

  const onToggleHamburger = () => setCollapsed((currentlyCollapsed) => {
    if (currentlyCollapsed) {
      // SideBar will be expanded after state change
      setSideBarState('PERSIST_EXPANDED');
    } else {
      // SideBar will be collapsed after state change
      setSideBarState('PERSIST_COLLAPSED');
    }
    return !currentlyCollapsed;
  });

  const windowListener = () => setWindowWidth(window.innerWidth);

  useEffect(
    () => {
      window.addEventListener('resize', windowListener);
      return () => window.removeEventListener('resize', windowListener);
    },
    [],
  );

  useEffect(
    () => {
      if (contentWidth < SIDEBAR_COLLAPSE_POINT && !collapsed) {
        if (sideBarState === 'PERSIST_EXPANDED') return;
        setCollapsed(true);
      } else if (SIDEBAR_COLLAPSE_POINT < contentWidth && collapsed) {
        if (sideBarState === 'PERSIST_COLLAPSED') return;
        setCollapsed(false);
      }
      setSideBarState('AUTO');
    },
    [collapsed, contentWidth, sideBarState],
  );

  return (
    <Sidebar
      backgroundColor="transparent"
      className={`min-vh-100 bg-secondary bg-gradient pr-4 text-white border-0 ${styles['sidebar'] || ''}`}
      onClick={() => (userDropdownOpen ? setUserDropdownOpen(false) : undefined)}
      collapsed={collapsed}
    >

      <div className={(collapsed ? 'py-2' : 'px-3')}>
        <CardImg
          alt="Rhino"
          src={logo}
          className="radial-metal-logo"
        />
      </div>
      <CardImgOverlay>
        <div className="d-flex justify-content-end">
          <Hamburger
            toggled={!collapsed}
            color="var(--bs-primary)"
            onToggle={onToggleHamburger}
          />
        </div>
      </CardImgOverlay>
      <div className="text-center">
        <h3>RHINO</h3>
        <span className="text-center text-white col-lg">{ userToken && userToken.org.name }</span>
      </div>
      <Menu className="mb-auto">
        <Dropdown
          isOpen={userDropdownOpen}
          toggle={toggleUserDropdownOpen}
          direction="end"
          className="mb-5 rounded-0"
        >
          <DropdownToggle nav>
            <MenuItem
              component="div"
              className={styles['menu-item']}
              key="/Profile"
              icon={<FaUser />}
            >
              <span>{formatNameAndRankFromToken(userToken)}</span>
            </MenuItem>
          </DropdownToggle>

          <DropdownMenu
            end
            data-bs-popper="static"
            container="body"
            className="rounded-0 rounded-end shadow-lg p-0"
          >

            <EditProfileModal
              modalOpen={editProfileModalOpen}
              setModalOpen={setEditProfileModalOpen}
              onClick={toggleUserDropdownOpen}
            />

            <DropdownItem
              onClick={() => {
                toggleUserDropdownOpen();
                navigate('/Admin');
              }}
              className="rounded"
            >
              Users
            </DropdownItem>

            <DropdownItem divider />

            <DropdownItem
              // onClick={() => window.open(PWA_APK_DOWNLOAD_URL, '_blank')}
              onClick={() => setPwaDownloadModalOpen(true)}
            >
              Mobile App
            </DropdownItem>
            <DownloadPwaModal
              isOpen={pwaDownloadModalOpen}
              setIsOpen={setPwaDownloadModalOpen}
            />

            <DropdownItem divider />

            <DropdownItem
              onClick={() => logout()}
              className="rounded"
            >
              Log out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {
          viewRoutes.filter((route) => (
            !route.hideSidebarLink
            && (
              route.allowedRoles && userToken
                ? route.allowedRoles?.includes(userToken?.user.role)
                : true
            )
          ))
            .map((route) => (
              <MenuItem
                className={styles['menu-item']}
                key={route.path}
                icon={route.icon}
                component={<Link to={route.path} />}
              >
                {route.label}
              </MenuItem>
            ))
        }
      </Menu>
    </Sidebar>
  );
}

export default SideBar;
