import React from 'react';
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  InputGroup,
  InputGroupText,
  Form,
  Label,
  Container,
  FormGroup,
} from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import TogglePasswordIcon from 'components/fields/TogglePasswordIcon';
import useLogout from 'hooks/UseLogout';
import type { IZxcvbn } from 'types/zxcvbn';

import styles from './Login.module.css';

interface IPasswordReset {
  email: string,
  password: string,
  confirmPassword?: string,
  token: string | null,
}

interface IPasswordResetErrors {
  formErrors: string[],
  passwordResetErrors: string | undefined,
}

const errorClass = `is-invalid ${styles['no-blur'] || ''}`;
const passwordVisibilityClasses = 'mb-3 bg-white border-start-0 rounded-end toggle-pw-visibility';
const passwordResetTitleClasses = `px-4 text-primary ${styles['text-shadow'] || ''}`;

function PasswordResetErrors({ formErrors, passwordResetErrors }: IPasswordResetErrors) {
  if (formErrors.length === 0 && !passwordResetErrors) {
    return <div />;
  }

  if (formErrors.length === 1 && formErrors[0] === 'confirmPassword') {
    return <Alert className="mb-0 text-center" color="danger">Passwords do not match</Alert>;
  }

  if (formErrors.length > 0) {
    return <Alert className="mb-0 text-center" color="danger">Please complete all fields</Alert>;
  }

  if (passwordResetErrors) {
    try {
      const zxcvbnResponse = JSON.parse(passwordResetErrors) as IZxcvbn;

      return (
        <Alert className="mb-0 text-center" color="danger">
          {`${zxcvbnResponse.warning}. ${zxcvbnResponse.suggestions.join(' ')}`}
        </Alert>
      );
    } catch {
      return <Alert className="mb-0 text-center" color="danger">{passwordResetErrors.replaceAll('"', '')}</Alert>;
    }
  }

  return <div />;
}

function PasswordReset() {
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();
  const logOut = useLogout();
  const [passwordResetErrors, setPasswordResetErrors] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IPasswordReset>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const emailFormPasswordReset = register('email', { required: true });
  const passwordFormPasswordReset = register('password', { required: true });
  const confirmPasswordFormPasswordReset = register(
    'confirmPassword',
    {
      required: true,
      validate: (confirmPassword: string | undefined) => (watch('password') === confirmPassword),
    },
  );

  const onSubmit = (formData: IPasswordReset) => {
    const formKeys = Object.keys(formData);
    const postData = formKeys.reduce((currentPostData: Record<string, string>, stringKey) => {
      const key = stringKey as keyof IPasswordReset;
      if (key !== 'confirmPassword') {
        return {
          ...currentPostData,
          [key.toLowerCase()]: String(formData[key]),
        };
      }

      return currentPostData;
    }, { token: searchParameters.get('token') || '' });
    axios.post('/auth/v1/reset', postData)
      .then(() => navigate('/'))
      .catch(async (error: Response) => {
        const errorText = await error.text();
        setPasswordResetErrors(errorText);
      });
  };

  React.useEffect(() => logOut, [logOut]);

  if (!searchParameters.get('token')) {
    navigate('/');
  }

  return (
    <Container className="d-flex flex-column vh-100">
      <div className="d-flex justify-content-center align-items-center flex-grow-1 col-md-12 col-lg-6 offset-lg-3">
        <Form
          className="bg-white container-lg g-0 rounded"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Card className="card-white">
            <CardHeader className="text-center bg-secondary bg-gradient">
              <CardTitle className={passwordResetTitleClasses} tag="h3">
                Password Reset
              </CardTitle>
            </CardHeader>
            <CardBody className="px-4">
              <FormGroup floating className="shadow-sm">
                <input
                  type="text"
                  className={`form-control ${(errors.email ? errorClass : '')}`}
                  id="inputEmailInput"
                  autoComplete="off"
                  placeholder=" "
                  onChange={emailFormPasswordReset.onChange}
                  onBlur={emailFormPasswordReset.onBlur}
                  name={emailFormPasswordReset.name}
                  ref={emailFormPasswordReset.ref}
                />
                <Label htmlFor="inputEmailInput">Email address</Label>
              </FormGroup>
              <InputGroup size="sm" className="mb-3">
                <FormGroup floating className="shadow-sm">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className={`form-control border-end-0 ${(errors.password ? errorClass : '')}`}
                    autoComplete="new-password"
                    placeholder=" "
                    id="inputPassword"
                    onChange={passwordFormPasswordReset.onChange}
                    onBlur={passwordFormPasswordReset.onBlur}
                    name={passwordFormPasswordReset.name}
                    ref={passwordFormPasswordReset.ref}
                  />
                  <Label htmlFor="inputPassword">Password</Label>
                </FormGroup>
                <InputGroupText
                  className={`${passwordVisibilityClasses}  ${(errors.password ? 'border-danger' : '')}`}
                  onClick={() => setShowPassword(!showPassword)}
                  title="Show/Hide password"
                >
                  <TogglePasswordIcon showPassword={showPassword} />
                </InputGroupText>
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <FormGroup floating className="shadow-sm">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className={`form-control border-end-0 ${(errors.confirmPassword || errors.password ? errorClass : '')}`}
                    autoComplete="new-password"
                    id="inputConfirmPassword"
                    placeholder=" "
                    onChange={confirmPasswordFormPasswordReset.onChange}
                    onBlur={confirmPasswordFormPasswordReset.onBlur}
                    name={confirmPasswordFormPasswordReset.name}
                    ref={confirmPasswordFormPasswordReset.ref}
                  />
                  <Label htmlFor="inputConfirmPassword">Confirm Password</Label>
                </FormGroup>
                <InputGroupText
                  className={`${passwordVisibilityClasses} ${(errors.confirmPassword || errors.password ? 'border-danger' : '')}`}
                  onClick={() => setShowPassword(!showPassword)}
                  title="Show/Hide password"
                >
                  <TogglePasswordIcon showPassword={showPassword} />
                </InputGroupText>
              </InputGroup>

            </CardBody>
            <PasswordResetErrors
              formErrors={Object.keys(errors)}
              passwordResetErrors={passwordResetErrors}
            />
            <CardFooter className="d-flex justify-content-center px-4 bg-secondary bg-gradient text-light">
              <input
                className="btn btn-primary text-dark"
                type="submit"
                value="Reset Password"
              />
            </CardFooter>
          </Card>
        </Form>
      </div>
    </Container>
  );
}

export default PasswordReset;
