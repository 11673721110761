import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { IOrganizations } from 'types/IOrganizations';

const useGetOrganizations = () => (
  useQuery({
    queryKey: ['GetOrganizations'],
    queryFn: async () => {
      const orgs = await axios.get('/auth/v1/organizations');
      return orgs.data as IOrganizations[];
    },
  })
);

export {
  useGetOrganizations, // eslint-disable-line import/prefer-default-export
};
