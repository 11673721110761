import React from 'react';
import { useAtomValue } from 'jotai';
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import { FaFilePdf } from 'react-icons/fa';

import { useGetRecentVehicleAF1800Report, useAf1800ReportPdf } from 'apiHooks/Af1800Reports.Hook';
import emptyAf1800PdfAtom from 'store/EmptyAf1800Pdf.store';
import PdfViewer from 'components/shared/PdfViewer/PdfViewer';

import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { populateSinglePdf } from './Af1800PdfFormUtils';

// *************************************************************************

/**
 *
 * @returns
 */
function AF1800ReportModal({ regNumber }: { regNumber: string }) {
  const [modal, setModal] = React.useState(false);
  const [pdfData, setPdfData] = React.useState<Uint8Array | undefined>();
  const { data } = useGetRecentVehicleAF1800Report(regNumber, modal);
  useAf1800ReportPdf(modal);
  const pdfFile = useAtomValue(emptyAf1800PdfAtom);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  React.useEffect(() => {
    if (data && pdfFile) {
      populateSinglePdf(data, pdfFile)
        .then((pdf) => pdf && setPdfData(pdf))
        .catch(() => {});
    }
  }, [data, pdfFile]);

  return (
    <div>
      <Button color="link" onClick={openModal} aria-label={`Download current AF1800 for ${regNumber}`}>
        <FaFilePdf />
      </Button>
      <Modal className="pdf-modal" isOpen={modal} toggle={closeModal}>
        <RhinoModalHeader toggle={closeModal}>
          {`Current AF1800 for ${regNumber}`}
        </RhinoModalHeader>
        <ModalBody className="p-0">
          <PdfViewer pdfData={pdfData} filename={`${regNumber}-af1800`} />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default AF1800ReportModal;
