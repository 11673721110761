import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';

import { RANKS_SELECT_OPTIONS } from 'utils/Constants';
import type { ISelectObject } from 'types/ISelectObject';
import type { ITypeAheadReactHookForm } from 'types/ITypeAheadReactHookForm';
import customFilterBy from 'utils/helpers/customFilterBy.Helper';

function RanksSelect({
  className,
  id,
  onValueChange,
  onBlur,
  name,
  formRef,
  defaultValue,
  value,
  isInvalid,
}: ITypeAheadReactHookForm<ISelectObject | undefined>) {
  return (
    <Typeahead
      filterBy={customFilterBy<ISelectObject>('label')}
      id={id}
      className={className || ''}
      highlightOnlyResult
      options={RANKS_SELECT_OPTIONS}
      labelKey={(org) => (org as ISelectObject).label}
      placeholder=" "
      inputProps={{ name, value }}
      defaultSelected={RANKS_SELECT_OPTIONS.filter((x) => x.value === defaultValue)}
      onChange={(selected) => {
        if (!onValueChange) {
          return;
        }
        const rankSelected = selected as ISelectObject[];
        if (rankSelected.length === 0) {
          onValueChange(undefined);
        } else {
          onValueChange(rankSelected[0] as ISelectObject);
        }
      }}
      ref={formRef}
      size="lg"
      isInvalid={isInvalid || false}
      renderInput={({ inputRef, referenceElementRef, ...inputProperties }) => (
        <Hint>
          <FormGroup floating>
            <input
              {...inputProperties /* eslint-disable-line react/jsx-props-no-spreading */}
              className={`form-control ${className || ''} ${isInvalid ? 'is-invalid' : ''}`}
              id={id}
              onBlur={(event) => onBlur && onBlur(event)}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
            />
            <Label for={id}>Rank</Label>
          </FormGroup>
        </Hint>
      )}
    />
  );
}

export default RanksSelect;
